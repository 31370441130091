import styles from "./AppBarLogo.module.css";
import * as React from "react";

const AppBarLogo = ({gutter}) => <>
    {gutter && <div className={styles.gutter}/>}
    <img src='/images/4EVER.png' alt='4EVER-logo' className={styles.logo} />
</>;

export default AppBarLogo;

