export default [
    {country: "European Union", kwhPrice: 0.2192, emissionIntensity: 230.7},
    {country: "Euro area (19 countries)", kwhPrice: 0.2322},
    {country: "Austria", kwhPrice: 0.2216, emissionIntensity: 82.4},
    {country: "Belgium", kwhPrice: 0.2702, emissionIntensity: 161},
    {country: "Spain", kwhPrice: 0.2323, emissionIntensity: 156.4},
    {country: "France", kwhPrice: 0.1933, emissionIntensity: 51.1},
    {country: "Bulgaria", kwhPrice: 0.1024, emissionIntensity: 410.4},
    {country: "Czechia", kwhPrice: 0.1802, emissionIntensity: 436.6},
    {country: "Denmark", kwhPrice: 0.29, emissionIntensity: 109},
    {country: "Germany", kwhPrice: 0.3193, emissionIntensity: 311},
    {country: "Estonia", kwhPrice: 0.1324, emissionIntensity: 774.9},
    {country: "Ireland", kwhPrice: 0.2555, emissionIntensity: 278.6},
    {country: "Greece", kwhPrice: 0.168, emissionIntensity: 479.2},
    {country: "Croatia", kwhPrice: 0.1291, emissionIntensity: 133.8},
    {country: "Italy", kwhPrice: 0.2259, emissionIntensity: 213.4},
    {country: "Cyprus", kwhPrice: 0.1976, emissionIntensity: 620.9},
    {country: "Latvia", kwhPrice: 0.1403, emissionIntensity: 106.5},
    {country: "Lithuania", kwhPrice: 0.1348, emissionIntensity: 45.4},
    {country: "Luxembourg", kwhPrice: 0.1988, emissionIntensity: 58.5},
    {country: "Hungary", kwhPrice: 0.1003, emissionIntensity: 216.4},
    {country: "Malta", kwhPrice: 0.1279, emissionIntensity: 379},
    {country: "Netherlands", kwhPrice: 0.5781, emissionIntensity: 328.4},
    {country: "Poland", kwhPrice: 0.1548, emissionIntensity: 709.8},
    {country: "Portugal", kwhPrice: 0.2089, emissionIntensity: 198.4},
    {country: "Romania", kwhPrice: 0.1536, emissionIntensity: 299.5},
    {country: "Slovenia", kwhPrice: 0.1662, emissionIntensity: 217.8},
    {country: "Slovakia", kwhPrice: 0.1668, emissionIntensity: 101.7},
    {country: "Finland", kwhPrice: 0.1767, emissionIntensity: 68.6},
    {country: "Sweden", kwhPrice: 0.1791, emissionIntensity: 8.8},
    {country: "Iceland", kwhPrice: 0.1356, emissionIntensity: 230.7}, //default EU emission
    {country: "Liechtenstein", kwhPrice: 0.2071, emissionIntensity: 230.7}, //default EU emission
    {country: "Norway", kwhPrice: 0.1826, emissionIntensity: 230.7}, //default EU emission
    {country: "Montenegro", kwhPrice: 0.098, emissionIntensity: 230.7}, //default EU emission
    {country: "North Macedonia", kwhPrice: 0.0841, emissionIntensity: 230.7}, //default EU emission
    {country: "Serbia", kwhPrice: 0.0791, emissionIntensity: 230.7}, //default EU emission
    {country: "Turkey", kwhPrice: 0.0834, emissionIntensity: 230.7}, //default EU emission
    {country: "Bosnia and Herzegovina", kwhPrice: 0.0875, emissionIntensity: 230.7}, //default EU emission
    {country: "Kosovo", kwhPrice: 0.0605, emissionIntensity: 230.7}, //default EU emission
    {country: "Moldova", kwhPrice: 0.0851, emissionIntensity: 230.7}, //default EU emission
    {country: "Ukraine", kwhPrice: 0.0485, emissionIntensity: 230.7}, //default EU emission
    {country: "Georgia", kwhPrice: 0.0631, emissionIntensity: 230.7}, //default EU emission
    {country: "Albania", kwhPrice: 0.0485, emissionIntensity: 230.7} //default EU emission
]