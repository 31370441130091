const calculateResults = (area, countryKwhPrice, countryEmissionImpact, heatingEfficiencyElec, heatingEfficiencyGas, coolingEfficiency, inputArray) => {
    const blueCoating = inputArray[0];
    const darkCoating = inputArray[1];
    const noCoating = inputArray[2];

    const noCoatingResults = {
        grossElecKwh: calculateElecHeatingDemands(area, noCoating.heatingKwh, heatingEfficiencyElec),
        grossGasKwh: calculateGasHeatingDemands(area, noCoating.heatingKwh, heatingEfficiencyGas),
        grossCoolingKwh: calculateCoolingDemands(area, noCoating.coolingKwh, coolingEfficiency),
        overheatingHoursSavings: noCoating.overheatingSavings
    }

    function ForEverBlueResults(){
        this.grossElecKwh = calculateElecHeatingDemands(area, blueCoating.heatingKwh, heatingEfficiencyElec);
        this.grossGasKwh = calculateGasHeatingDemands(area, blueCoating.heatingKwh, heatingEfficiencyGas);
        this.grossCoolingKwh = calculateCoolingDemands(area, blueCoating.coolingKwh, coolingEfficiency);
        this.grossElecKwhDiff = format(percIncrease(noCoatingResults.grossElecKwh,this.grossElecKwh),0, true);
        this.grossGasKwhDiff = format(percIncrease(noCoatingResults.grossGasKwh, this.grossGasKwh),0, true);
        this.grossCoolingKwhDiff = -format(percIncrease(noCoatingResults.grossCoolingKwh, this.grossCoolingKwh),0, true);
        this.kwhDifference = noCoatingResults.grossCoolingKwh - this.grossCoolingKwh;
        this.annualSaving = calculateAnnualSaving(this.kwhDifference, this.grossCoolingKwhDiff, countryKwhPrice);
        this.annualCo2Reduction = format(this.kwhDifference * countryEmissionImpact,0, true);
        this.overheatingHoursSavings = blueCoating.overheatingSavings
    }

    function ForEverDarkResults(){
        this.grossElecKwh = calculateElecHeatingDemands(area, darkCoating.heatingKwh, heatingEfficiencyElec);
        this.grossGasKwh = calculateGasHeatingDemands(area, darkCoating.heatingKwh, heatingEfficiencyGas);
        this.grossCoolingKwh = calculateCoolingDemands(area, darkCoating.coolingKwh, coolingEfficiency);
        this.grossElecKwhDiff = format(percIncrease(noCoatingResults.grossElecKwh,this.grossElecKwh),0, true);
        this.grossGasKwhDiff = format(percIncrease(noCoatingResults.grossGasKwh, this.grossGasKwh),0, true);
        this.grossCoolingKwhDiff = -format(percIncrease(noCoatingResults.grossCoolingKwh, this.grossCoolingKwh),0, true)
        this.kwhDifference = noCoatingResults.grossCoolingKwh - this.grossCoolingKwh
        this.annualSaving = calculateAnnualSaving(this.kwhDifference, this.grossCoolingKwhDiff, countryKwhPrice)
        this.annualCo2Reduction = format(this.kwhDifference * countryEmissionImpact,0, true)
        this.overheatingHoursSavings = darkCoating.overheatingSavings
    }

    return {
        forEverBlueResults: new ForEverBlueResults(),
        forEverDarkResults: new ForEverDarkResults(),
        noCoatingResults: noCoatingResults,
    }
};

const calculateAnnualSaving = (kwhDifference, grossCoolingKwhDiff, kwhPrice) => {
    return format((kwhDifference - grossCoolingKwhDiff) * kwhPrice,0, true);
};

const calculateElecHeatingDemands = (area, heatingKwh, heatingEfficiencyElec) => {
    const netKwh = heatingKwh * area;
    return format(netKwh / heatingEfficiencyElec) ;

};

const calculateGasHeatingDemands = (area, heatingKwh, heatingEfficiencyGas) => {
    const netKwh = heatingKwh * area;
    return  format(netKwh / heatingEfficiencyGas);

};

const calculateCoolingDemands = (area, coolingKwh, coolingEfficiency) => {
    const netKwh = coolingKwh * area;
    return format(netKwh / coolingEfficiency);
};

const percIncrease = (a, b) => {
    let percent;
    if(b !== 0) {
        if(a !== 0) {
            percent = (b - a) / a * 100;
        } else {
            percent = b * 100;
        }
    } else {
        percent = - a * 100;
    }
    return percent;
}

const s00p = {
    price: 49.95,
    liters: 5,
    area: 200
};

const calculates00p = area => {
    const buckets = Math.ceil(area / s00p.area);
    const price = buckets * s00p.price;
    return {
        price: format(price, 2),
        liters: buckets * s00p.liters
    };
};

const foreverBlue = {
    price: 104.95,
    liters: 1,
    area: 9
};

const calculate4everBlue = (area) => {
    const buckets = Math.ceil(area / foreverBlue.area);
    const price = buckets * foreverBlue.price;
    return {
        price: format(price, 2),
        liters: buckets * foreverBlue.liters
    };
};

const foreverDark = {
    price: 104.95,
    liters: 1,
    area: 9
};

const calculate4everDark = (area) => {
    const buckets = Math.ceil(area / foreverDark.area);
    const price = buckets * foreverDark.price;
    return {
        price: format(price, 2),
        liters: buckets * foreverDark.liters
    };
};

const format = (value, precision = 0, intl = false) => {
    const p = Math.pow(10, precision);
    const r = Math.round(value * p) / p;

    if (!intl){
        return r;
    }
    
    return Intl.NumberFormat().format(r);
};

export {
    calculateResults,
    calculates00p,
    calculate4everBlue,
    calculate4everDark,
    calculateAnnualSaving
};
