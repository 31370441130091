export default [
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "162.84184450936",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "164.0909178681589",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "158.8080810082111",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "164.81183340362114",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "167.09635442666777",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "157.65366735625332",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "167.11926577506222",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "170.29519227444555",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "157.46715382466778",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "111.64018887438779",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "112.82571054551555",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "107.88601705227354",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "113.82910571124224",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "115.94270113936223",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "107.37799140618134",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "116.38859571474555",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "119.28572977913223",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "107.8228311011061",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "86.38197661087821",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "87.50646243374311",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "82.833666315089",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "88.74079752031477",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "90.7375416243681",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "82.75641251223311",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "91.48502081735278",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "94.18522763290056",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "83.58138495314711",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "268.24185977560666",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "270.17235984736107",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "261.9102217069411",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "269.7356334962022",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "273.3077005010233",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "258.4805602926089",
   "coolingKwh": "0.003876361578825089",
   "overheatingSavings": 2
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "271.86106867126443",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "276.8026692252489",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "256.7752908564489",
   "coolingKwh": "0.17723501171188222",
   "overheatingSavings": 24
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "193.67903571119",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "195.66356603324778",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "187.22444719256",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "195.00983202343335",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "198.60589688617222",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "184.02005626033556",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "197.22189709052887",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "202.06228723065334",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "182.75066966435665",
   "coolingKwh": "0.15272964480587556",
   "overheatingSavings": 27
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "156.50672776769667",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "158.52331823500333",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "149.99209622206334",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "157.7518998381389",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "161.35310163509",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "146.9887364312922",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "160.05520155030334",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "164.80422232653555",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "146.09065268295444",
   "coolingKwh": "0.16883625805301666",
   "overheatingSavings": 36
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "124.71228021923223",
   "coolingKwh": "0.0018240581910414111",
   "overheatingSavings": 1
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "125.87375374906333",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "120.96957162223556",
   "coolingKwh": "0.07577686198689544",
   "overheatingSavings": 12
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "128.63487972407222",
   "coolingKwh": "0.1371472245344111",
   "overheatingSavings": 20
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "130.7350702544678",
   "coolingKwh": "0.05812803093829889",
   "overheatingSavings": 10
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "122.25736276893888",
   "coolingKwh": "0.9349850882159557",
   "overheatingSavings": 92
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "132.88120048476",
   "coolingKwh": "0.5509693355216567",
   "overheatingSavings": 58
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "135.74054216121334",
   "coolingKwh": "0.22963203583401778",
   "overheatingSavings": 27
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "124.5839935797611",
   "coolingKwh": "3.5782265222176335",
   "overheatingSavings": 238
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "73.54907575872568",
   "coolingKwh": "0.004183767385466122",
   "overheatingSavings": 2
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "74.56736839555744",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "70.43453499329554",
   "coolingKwh": "0.13111525745773556",
   "overheatingSavings": 24
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "77.88889139018755",
   "coolingKwh": "0.20761057886344997",
   "overheatingSavings": 31
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "79.64517870704779",
   "coolingKwh": "0.08134140915581778",
   "overheatingSavings": 16
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "72.65982644589188",
   "coolingKwh": "1.8164589786907113",
   "overheatingSavings": 194
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "82.47945082084911",
   "coolingKwh": "0.968227828948429",
   "overheatingSavings": 113
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "84.852041010872",
   "coolingKwh": "0.39229525887494776",
   "overheatingSavings": 57
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "75.60047564366178",
   "coolingKwh": "6.257901328218044",
   "overheatingSavings": 450
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "48.87646371753522",
   "coolingKwh": "0.01343579453358789",
   "overheatingSavings": 6
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "49.76087843815256",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "46.174698013094556",
   "coolingKwh": "0.24978668194199885",
   "overheatingSavings": 52
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "53.45670412072345",
   "coolingKwh": "0.4060285677064189",
   "overheatingSavings": 74
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "55.01255612293522",
   "coolingKwh": "0.13185440174602778",
   "overheatingSavings": 27
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "48.861557053177336",
   "coolingKwh": "3.0330733203797666",
   "overheatingSavings": 337
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "58.19800105925211",
   "coolingKwh": "1.5963497708059111",
   "overheatingSavings": 196
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "60.32200751863256",
   "coolingKwh": "0.656750635911909",
   "overheatingSavings": 103
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "52.07841823779344",
   "coolingKwh": "8.972058420838632",
   "overheatingSavings": 708
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "191.54732439818443",
   "coolingKwh": "0.4401569130824767",
   "overheatingSavings": 70
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "193.22787520158",
   "coolingKwh": "0.32293623477374556",
   "overheatingSavings": 57
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "186.19334339977777",
   "coolingKwh": "1.0700470244317877",
   "overheatingSavings": 148
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "196.85135129189",
   "coolingKwh": "1.469863645573089",
   "overheatingSavings": 175
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "199.8277507419811",
   "coolingKwh": "0.9144565124502778",
   "overheatingSavings": 120
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "187.89555781817333",
   "coolingKwh": "4.743123240877533",
   "overheatingSavings": 402
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "202.72524831951225",
   "coolingKwh": "3.374175968144511",
   "overheatingSavings": 299
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "206.71884033662002",
   "coolingKwh": "2.042289397235633",
   "overheatingSavings": 204
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "191.45307044755666",
   "coolingKwh": "11.509956548407667",
   "overheatingSavings": 685
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "117.05660837179667",
   "coolingKwh": "0.41389989145635775",
   "overheatingSavings": 87
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "118.63905826382889",
   "coolingKwh": "0.26949876459775",
   "overheatingSavings": 66
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "112.08249849998334",
   "coolingKwh": "1.3479469774923665",
   "overheatingSavings": 216
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "122.68965528194332",
   "coolingKwh": "1.802790812711211",
   "overheatingSavings": 243
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "125.43752197274665",
   "coolingKwh": "1.057800285963071",
   "overheatingSavings": 176
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "114.84312772761332",
   "coolingKwh": "6.690418077951188",
   "overheatingSavings": 637
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "129.06241063833664",
   "coolingKwh": "4.588532530619744",
   "overheatingSavings": 480
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "132.58957869838443",
   "coolingKwh": "2.5558145867321667",
   "overheatingSavings": 313
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "119.32481276205777",
   "coolingKwh": "15.11053691924811",
   "overheatingSavings": 1030
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "80.21558877643866",
   "coolingKwh": "0.52973867620353",
   "overheatingSavings": 135
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "81.69306063054422",
   "coolingKwh": "0.31298797152226665",
   "overheatingSavings": 89
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "75.69065777417433",
   "coolingKwh": "1.796827922684111",
   "overheatingSavings": 334
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "86.17233921633411",
   "coolingKwh": "2.3437432969378116",
   "overheatingSavings": 375
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "88.69585536990256",
   "coolingKwh": "1.3427738460897556",
   "overheatingSavings": 239
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "79.26834169286178",
   "coolingKwh": "8.57948031550939",
   "overheatingSavings": 938
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "92.862704315058",
   "coolingKwh": "5.809625933690189",
   "overheatingSavings": 658
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "96.06508745983867",
   "coolingKwh": "3.291976258230878",
   "overheatingSavings": 454
 },
 {
   "location": "London",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "84.29230770462188",
   "coolingKwh": "18.150360192310668",
   "overheatingSavings": 1431
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "106.19185986531488",
   "coolingKwh": "0.0008001472207643088",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "107.44802018822743",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "102.25286644266566",
   "coolingKwh": "0.07987029484849667",
   "overheatingSavings": 14
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "110.65884942652612",
   "coolingKwh": "0.1457544324556478",
   "overheatingSavings": 24
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "112.86465545628334",
   "coolingKwh": "0.0599447326094051",
   "overheatingSavings": 12
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "104.15004864077501",
   "coolingKwh": "1.1228646088845222",
   "overheatingSavings": 121
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "115.52239607556668",
   "coolingKwh": "0.643844495099231",
   "overheatingSavings": 73
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "118.4763506611",
   "coolingKwh": "0.25178724831592997",
   "overheatingSavings": 33
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "107.18884885446033",
   "coolingKwh": "4.295325776333355",
   "overheatingSavings": 303
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "55.839005049034675",
   "coolingKwh": "0.013803471648564445",
   "overheatingSavings": 5
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "56.86959826709111",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "52.69922469088378",
   "coolingKwh": "0.20226925420594447",
   "overheatingSavings": 40
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "60.837007955968325",
   "coolingKwh": "0.3235973797634167",
   "overheatingSavings": 63
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "62.62463538058633",
   "coolingKwh": "0.11976357128817224",
   "overheatingSavings": 26
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "55.669954673389775",
   "coolingKwh": "2.6244422042286",
   "overheatingSavings": 310
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "66.10251020553234",
   "coolingKwh": "1.3715635304452667",
   "overheatingSavings": 176
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "68.49823834354689",
   "coolingKwh": "0.5651222456189722",
   "overheatingSavings": 94
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "59.289711477289224",
   "coolingKwh": "8.339187517186756",
   "overheatingSavings": 673
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "31.807887724235783",
   "coolingKwh": "0.05407050307558178",
   "overheatingSavings": 20
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "32.654690356241666",
   "coolingKwh": "0.014597249787493891",
   "overheatingSavings": 6
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "29.313076589864668",
   "coolingKwh": "0.6439307351073567",
   "overheatingSavings": 162
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "37.096738746733664",
   "coolingKwh": "0.8012893069520622",
   "overheatingSavings": 165
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "38.58808648998767",
   "coolingKwh": "0.28834624948116",
   "overheatingSavings": 84
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "32.886564568405106",
   "coolingKwh": "5.088568693223967",
   "overheatingSavings": 704
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "42.495310456131776",
   "coolingKwh": "2.5976320834908115",
   "overheatingSavings": 373
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "44.54384970240111",
   "coolingKwh": "1.1299493231878333",
   "overheatingSavings": 194
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "36.856752824472",
   "coolingKwh": "12.783716751891665",
   "overheatingSavings": 1222
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "163.02334277772334",
   "coolingKwh": "0.3764921506547867",
   "overheatingSavings": 71
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "164.82918008705335",
   "coolingKwh": "0.2788055261896667",
   "overheatingSavings": 55
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "157.3097018504467",
   "coolingKwh": "0.9893569962611944",
   "overheatingSavings": 155
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "169.10515471892666",
   "coolingKwh": "1.4010567134153777",
   "overheatingSavings": 189
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "172.26628925648888",
   "coolingKwh": "0.8365133718159056",
   "overheatingSavings": 124
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "159.70748423876668",
   "coolingKwh": "4.953695460292689",
   "overheatingSavings": 463
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "175.8659290566989",
   "coolingKwh": "3.461281655185011",
   "overheatingSavings": 340
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "180.0549380241511",
   "coolingKwh": "2.0206094026129002",
   "overheatingSavings": 230
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "164.24024846743887",
   "coolingKwh": "12.520453340977333",
   "overheatingSavings": 805
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "89.02602492592845",
   "coolingKwh": "0.4558369868161422",
   "overheatingSavings": 123
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "90.68444037689133",
   "coolingKwh": "0.28471031887112336",
   "overheatingSavings": 84
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "83.91645118554578",
   "coolingKwh": "1.5196380219386556",
   "overheatingSavings": 306
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "95.55942731501443",
   "coolingKwh": "1.9879066887778223",
   "overheatingSavings": 336
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "98.40380869763055",
   "coolingKwh": "1.1524928148217666",
   "overheatingSavings": 227
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "87.71078534926465",
   "coolingKwh": "7.71295356809089",
   "overheatingSavings": 889
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "102.88155352509156",
   "coolingKwh": "5.128820462201255",
   "overheatingSavings": 620
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "106.48456888591068",
   "coolingKwh": "2.8130337089905777",
   "overheatingSavings": 401
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "93.24943793729477",
   "coolingKwh": "17.271135019850444",
   "overheatingSavings": 1369
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "52.970507851249444",
   "coolingKwh": "0.7800502126268034",
   "overheatingSavings": 255
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "54.35229496605077",
   "coolingKwh": "0.4591903058057645",
   "overheatingSavings": 180
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "49.022120124127",
   "coolingKwh": "2.7173987495311445",
   "overheatingSavings": 674
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "60.253063736171775",
   "coolingKwh": "3.183301126280889",
   "overheatingSavings": 654
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "62.517835839089216",
   "coolingKwh": "1.7704628796434336",
   "overheatingSavings": 421
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "53.96300458861922",
   "coolingKwh": "11.1061987625139",
   "overheatingSavings": 1552
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "67.95731872397111",
   "coolingKwh": "7.264217172764045",
   "overheatingSavings": 1015
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "70.890112666817",
   "coolingKwh": "4.122993517428411",
   "overheatingSavings": 698
 },
 {
   "location": "London",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "59.9468868775329",
   "coolingKwh": "22.07685673089678",
   "overheatingSavings": 2089
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "121.22011487441557",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "122.61557555476888",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "116.83124644390223",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "123.65481732681778",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "126.14993307386888",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "115.93637942341554",
   "coolingKwh": "0.12465463293623666",
   "overheatingSavings": 18
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "126.35082481441556",
   "coolingKwh": "0.05239434018087823",
   "overheatingSavings": 10
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "129.82690154767778",
   "coolingKwh": "0.0037031607728414775",
   "overheatingSavings": 2
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "115.95650980493556",
   "coolingKwh": "0.9119042424182122",
   "overheatingSavings": 106
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "71.65492060292245",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "72.84743949747799",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "67.86990413470933",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "74.64777401577933",
   "coolingKwh": "0.003245270000486378",
   "overheatingSavings": 2
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "76.80974645401834",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "68.17655141559",
   "coolingKwh": "0.42789607857468553",
   "overheatingSavings": 77
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "77.86527760301989",
   "coolingKwh": "0.13825002278792334",
   "overheatingSavings": 26
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "80.84267395512455",
   "coolingKwh": "0.02442582056276311",
   "overheatingSavings": 6
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "69.30670456457767",
   "coolingKwh": "2.4396402965004667",
   "overheatingSavings": 286
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "47.53246236489678",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "48.56839617261022",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "44.289939267983996",
   "coolingKwh": "0.006327181636975666",
   "overheatingSavings": 4
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "50.944857362907555",
   "coolingKwh": "0.024406148593738224",
   "overheatingSavings": 7
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "52.82498357558856",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "45.465378722698325",
   "coolingKwh": "1.153591298436089",
   "overheatingSavings": 206
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "54.523481671847776",
   "coolingKwh": "0.3894063190566567",
   "overheatingSavings": 82
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "57.09269648238678",
   "coolingKwh": "0.06672835059038755",
   "overheatingSavings": 18
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "47.19948208103378",
   "coolingKwh": "4.54999023807319",
   "overheatingSavings": 628
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "188.36026851594667",
   "coolingKwh": "0.08154524165416623",
   "overheatingSavings": 15
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "190.47511188088333",
   "coolingKwh": "0.05114136387517566",
   "overheatingSavings": 12
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "181.53459671550553",
   "coolingKwh": "0.25123974730616777",
   "overheatingSavings": 48
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "191.09631281230222",
   "coolingKwh": "0.3517389320169989",
   "overheatingSavings": 56
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "194.97342509495667",
   "coolingKwh": "0.1922013256601767",
   "overheatingSavings": 39
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "179.06087181256112",
   "coolingKwh": "1.6157604961657555",
   "overheatingSavings": 196
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "194.30184152015",
   "coolingKwh": "1.0387483633612733",
   "overheatingSavings": 138
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "199.67307503271556",
   "coolingKwh": "0.5116475937200367",
   "overheatingSavings": 78
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "178.3666710320422",
   "coolingKwh": "4.587410001235089",
   "overheatingSavings": 432
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "114.65787723280445",
   "coolingKwh": "0.08175113772996388",
   "overheatingSavings": 28
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "116.58546126483556",
   "coolingKwh": "0.04145575225169689",
   "overheatingSavings": 15
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "108.6391365054359",
   "coolingKwh": "0.3954162921366166",
   "overheatingSavings": 92
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "118.2190903338011",
   "coolingKwh": "0.5582331651404788",
   "overheatingSavings": 113
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "121.59560656876222",
   "coolingKwh": "0.25800938464823114",
   "overheatingSavings": 62
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "108.42658937181154",
   "coolingKwh": "2.9290280952344667",
   "overheatingSavings": 458
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "122.37841561711778",
   "coolingKwh": "1.7241521255918",
   "overheatingSavings": 278
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "126.91094020655667",
   "coolingKwh": "0.8522701263209866",
   "overheatingSavings": 160
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "109.75147658689868",
   "coolingKwh": "8.032052745179334",
   "overheatingSavings": 944
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "78.57437340322122",
   "coolingKwh": "0.10868830627196756",
   "overheatingSavings": 39
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "80.26668718600001",
   "coolingKwh": "0.051556913395657",
   "overheatingSavings": 26
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "73.57306521800834",
   "coolingKwh": "0.7420837808552445",
   "overheatingSavings": 201
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "83.06246504706778",
   "coolingKwh": "0.9677813368943545",
   "overheatingSavings": 238
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "85.88208404780156",
   "coolingKwh": "0.43653890541225554",
   "overheatingSavings": 115
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "74.8184248784138",
   "coolingKwh": "4.8025441843587435",
   "overheatingSavings": 869
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "87.93908500898489",
   "coolingKwh": "2.733907970397478",
   "overheatingSavings": 519
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "91.76937357539822",
   "coolingKwh": "1.3438530711977332",
   "overheatingSavings": 283
 },
 {
   "location": "London",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "77.03633755697255",
   "coolingKwh": "11.94741546272211",
   "overheatingSavings": 1563
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "104.29417237253678",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "105.71304170379234",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "99.78397231300723",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "107.37000407869579",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "109.93249328883111",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "99.53744671147234",
   "coolingKwh": "0.20771471485552",
   "overheatingSavings": 33
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "110.72774335313",
   "coolingKwh": "0.09154462834004823",
   "overheatingSavings": 15
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "114.26066649185556",
   "coolingKwh": "0.015918198488515334",
   "overheatingSavings": 4
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "100.25834125805834",
   "coolingKwh": "1.4597803149631667",
   "overheatingSavings": 151
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "55.14297561002934",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "56.34849865707111",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "51.48120044458989",
   "coolingKwh": "0.00854118737279189",
   "overheatingSavings": 4
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "58.94364003728967",
   "coolingKwh": "0.029071598111885335",
   "overheatingSavings": 7
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "61.039461853677544",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "52.75810249416189",
   "coolingKwh": "1.0440788069299412",
   "overheatingSavings": 177
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "62.92308039374622",
   "coolingKwh": "0.3521939976352244",
   "overheatingSavings": 73
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "65.81437220781389",
   "coolingKwh": "0.07544774823289012",
   "overheatingSavings": 19
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "54.70442131002055",
   "coolingKwh": "4.3257750555326115",
   "overheatingSavings": 573
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "31.61581502852211",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "32.60404562787634",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "28.60363113937333",
   "coolingKwh": "0.10755611740417778",
   "overheatingSavings": 45
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "35.87992117162588",
   "coolingKwh": "0.17203638041843555",
   "overheatingSavings": 57
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "37.64797426164578",
   "coolingKwh": "0.017281973570806224",
   "overheatingSavings": 7
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "30.763264656614552",
   "coolingKwh": "2.939340763647011",
   "overheatingSavings": 668
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "40.214062332307776",
   "coolingKwh": "1.135196687407711",
   "overheatingSavings": 251
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "42.66815151847145",
   "coolingKwh": "0.3036290591205689",
   "overheatingSavings": 78
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "33.53229425501656",
   "coolingKwh": "8.677896773226333",
   "overheatingSavings": 1297
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "161.59807310573444",
   "coolingKwh": "0.088114936243055",
   "overheatingSavings": 23
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "163.80085859518778",
   "coolingKwh": "0.05125740081813188",
   "overheatingSavings": 13
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "154.5569259105478",
   "coolingKwh": "0.3134669083738622",
   "overheatingSavings": 59
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "165.2369528031311",
   "coolingKwh": "0.4402582999268078",
   "overheatingSavings": 74
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "169.21381630002554",
   "coolingKwh": "0.23837049881136887",
   "overheatingSavings": 48
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "153.2123251094711",
   "coolingKwh": "2.1243408240811443",
   "overheatingSavings": 274
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "169.44243563035445",
   "coolingKwh": "1.3433804939321332",
   "overheatingSavings": 190
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "174.88133003982225",
   "coolingKwh": "0.6691791383633389",
   "overheatingSavings": 106
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "153.8556690698189",
   "coolingKwh": "5.974641066005889",
   "overheatingSavings": 603
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "88.42751692239777",
   "coolingKwh": "0.13039805428904555",
   "overheatingSavings": 42
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "90.3633780577681",
   "coolingKwh": "0.06531606795633567",
   "overheatingSavings": 29
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "82.7549948594879",
   "coolingKwh": "0.6893356575836389",
   "overheatingSavings": 187
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "93.38583345405766",
   "coolingKwh": "0.9263077216079755",
   "overheatingSavings": 222
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "96.57584764401844",
   "coolingKwh": "0.4249900395772033",
   "overheatingSavings": 108
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "84.25183672829422",
   "coolingKwh": "4.609983518872832",
   "overheatingSavings": 822
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "98.90958535487921",
   "coolingKwh": "2.6642870647892445",
   "overheatingSavings": 483
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "103.13473988671188",
   "coolingKwh": "1.3351133338006111",
   "overheatingSavings": 277
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "86.8774999331659",
   "coolingKwh": "11.694937068658",
   "overheatingSavings": 1493
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "53.39851457522422",
   "coolingKwh": "0.28356661303182884",
   "overheatingSavings": 126
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "54.892594805268104",
   "coolingKwh": "0.12439783699142667",
   "overheatingSavings": 57
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "48.83129065703956",
   "coolingKwh": "1.7616072476472444",
   "overheatingSavings": 640
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "59.31020475184367",
   "coolingKwh": "1.9861139826549998",
   "overheatingSavings": 602
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "61.94465899599878",
   "coolingKwh": "0.9632083214529079",
   "overheatingSavings": 337
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "51.755180453884996",
   "coolingKwh": "8.590812917829332",
   "overheatingSavings": 1798
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "65.40292424387867",
   "coolingKwh": "4.753317630598667",
   "overheatingSavings": 1017
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "69.01406893043834",
   "coolingKwh": "2.4691849813355224",
   "overheatingSavings": 629
 },
 {
   "location": "London",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "55.55977668410089",
   "coolingKwh": "18.151375548921113",
   "overheatingSavings": 2457
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "94.3681982302607",
   "coolingKwh": "0.0015661876858697333",
   "overheatingSavings": 3
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "95.61013554435544",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "90.34652795532112",
   "coolingKwh": "0.1039640134932402",
   "overheatingSavings": 27
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "95.04683661874044",
   "coolingKwh": "0.2975111640964389",
   "overheatingSavings": 64
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "97.324907531606",
   "coolingKwh": "0.11092236379591434",
   "overheatingSavings": 27
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "87.95746782690333",
   "coolingKwh": "1.9564714138174335",
   "overheatingSavings": 246
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "96.09518972742266",
   "coolingKwh": "1.3993920277274334",
   "overheatingSavings": 192
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "99.25780145542112",
   "coolingKwh": "0.6538707844635268",
   "overheatingSavings": 109
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "86.51943166145834",
   "coolingKwh": "7.093255935434589",
   "overheatingSavings": 587
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "56.086767830535116",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "57.215920742976",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "52.504998300810996",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "57.046878794072676",
   "coolingKwh": "0.015984235720125778",
   "overheatingSavings": 10
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "59.07062348367566",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "50.861512662912666",
   "coolingKwh": "1.1588022464010443",
   "overheatingSavings": 208
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "58.34974074576433",
   "coolingKwh": "0.6560233975633",
   "overheatingSavings": 133
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "61.15011911260411",
   "coolingKwh": "0.14986652047694443",
   "overheatingSavings": 47
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "50.11240399703677",
   "coolingKwh": "6.634382056330345",
   "overheatingSavings": 698
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "37.64502602482233",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "38.66815535486167",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "34.48539814555711",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "38.830131675619995",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "40.645618035899886",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "33.49169991240488",
   "coolingKwh": "0.8595570710351045",
   "overheatingSavings": 191
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "40.334935711582",
   "coolingKwh": "0.3895580234828833",
   "overheatingSavings": 100
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "42.815823685596335",
   "coolingKwh": "0.027298616267306557",
   "overheatingSavings": 13
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "33.27119219796733",
   "coolingKwh": "6.846488104080822",
   "overheatingSavings": 821
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "167.57909789599557",
   "coolingKwh": "2.771449350906467",
   "overheatingSavings": 420
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "169.49078088924668",
   "coolingKwh": "2.285947068927011",
   "overheatingSavings": 366
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "161.3879628704589",
   "coolingKwh": "4.880666232601311",
   "overheatingSavings": 606
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "167.61603901318333",
   "coolingKwh": "6.320602643657211",
   "overheatingSavings": 689
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "171.11339933863778",
   "coolingKwh": "4.828162690255122",
   "overheatingSavings": 588
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "156.64569461359335",
   "coolingKwh": "12.871633515176779",
   "overheatingSavings": 1058
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "168.2560369232222",
   "coolingKwh": "11.041733138635221",
   "overheatingSavings": 931
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "173.12343283162113",
   "coolingKwh": "8.201221464039167",
   "overheatingSavings": 779
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "153.57930283247111",
   "coolingKwh": "23.994439855809887",
   "overheatingSavings": 1491
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "110.61025431215768",
   "coolingKwh": "0.7822784455667022",
   "overheatingSavings": 222
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "112.45953903208445",
   "coolingKwh": "0.5177817320315244",
   "overheatingSavings": 159
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "104.7104025676009",
   "coolingKwh": "2.2874353101396885",
   "overheatingSavings": 477
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "110.86447283310534",
   "coolingKwh": "3.5343159341217776",
   "overheatingSavings": 630
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "114.17535144709777",
   "coolingKwh": "2.2231203649290667",
   "overheatingSavings": 450
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "100.80738247646978",
   "coolingKwh": "10.304249285803966",
   "overheatingSavings": 1203
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "111.81990595081444",
   "coolingKwh": "8.214014890185034",
   "overheatingSavings": 1003
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "116.33484125734668",
   "coolingKwh": "5.2857606025593",
   "overheatingSavings": 769
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "98.60069586509512",
   "coolingKwh": "22.314551113919556",
   "overheatingSavings": 1783
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "82.53852255465311",
   "coolingKwh": "0.19703749989387778",
   "overheatingSavings": 102
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "84.31738415304378",
   "coolingKwh": "0.07852303610044511",
   "overheatingSavings": 53
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "76.98801103201144",
   "coolingKwh": "1.2630476475488",
   "overheatingSavings": 391
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "83.06193158328777",
   "coolingKwh": "2.3230828074679333",
   "overheatingSavings": 559
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "86.168431442301",
   "coolingKwh": "1.1991630037286223",
   "overheatingSavings": 350
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "73.76334950673389",
   "coolingKwh": "9.402762470961889",
   "overheatingSavings": 1375
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "84.30261279583189",
   "coolingKwh": "7.037207209184634",
   "overheatingSavings": 1093
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "88.49960402836867",
   "coolingKwh": "3.991986441052122",
   "overheatingSavings": 773
 },
 {
   "location": "Madrid",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "72.177280516046",
   "coolingKwh": "22.125944209619",
   "overheatingSavings": 2027
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "86.017594227617",
   "coolingKwh": "6.950677467226678",
   "overheatingSavings": 636
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "87.25422959265856",
   "coolingKwh": "5.8210203712226445",
   "overheatingSavings": 580
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "82.12165618589778",
   "coolingKwh": "11.580417553216444",
   "overheatingSavings": 871
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "87.98385730144922",
   "coolingKwh": "13.861161936958442",
   "overheatingSavings": 940
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "90.1562573604281",
   "coolingKwh": "10.745778178562556",
   "overheatingSavings": 807
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "81.46962196984921",
   "coolingKwh": "27.294074355554667",
   "overheatingSavings": 1379
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "90.43454030227623",
   "coolingKwh": "22.75431187238267",
   "overheatingSavings": 1210
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "93.35479430714422",
   "coolingKwh": "16.969391842561336",
   "overheatingSavings": 1031
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "82.21950282851854",
   "coolingKwh": "47.28797072511978",
   "overheatingSavings": 1789
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "46.61477933724611",
   "coolingKwh": "6.260349288274722",
   "overheatingSavings": 809
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "47.71085471825077",
   "coolingKwh": "4.946353743813122",
   "overheatingSavings": 701
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "43.22194778452567",
   "coolingKwh": "11.92605380854911",
   "overheatingSavings": 1220
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "48.77832189417122",
   "coolingKwh": "14.324989536160556",
   "overheatingSavings": 1282
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "50.691708614302996",
   "coolingKwh": "10.5087964484164",
   "overheatingSavings": 1044
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "43.123421883649556",
   "coolingKwh": "30.086323548256228",
   "overheatingSavings": 1917
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "51.324124948255665",
   "coolingKwh": "24.261496567550335",
   "overheatingSavings": 1602
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "53.91816710046355",
   "coolingKwh": "17.61029629473533",
   "overheatingSavings": 1362
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "44.22869170072967",
   "coolingKwh": "52.43525387864789",
   "overheatingSavings": 2460
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "27.840637302356004",
   "coolingKwh": "6.618082389484378",
   "overheatingSavings": 1077
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "28.81970633858922",
   "coolingKwh": "5.040288535375645",
   "overheatingSavings": 901
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "24.86398998198189",
   "coolingKwh": "13.473448998168221",
   "overheatingSavings": 1682
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "30.166501027680887",
   "coolingKwh": "15.800494708457558",
   "overheatingSavings": 1675
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "31.87059479211777",
   "coolingKwh": "11.423793150471667",
   "overheatingSavings": 1397
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "25.323709797121555",
   "coolingKwh": "33.57080928543522",
   "overheatingSavings": 2508
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "32.798506089172",
   "coolingKwh": "26.454902055169995",
   "overheatingSavings": 2034
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "35.11123408333201",
   "coolingKwh": "19.074418498715332",
   "overheatingSavings": 1705
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "26.601781599635114",
   "coolingKwh": "57.502423109949554",
   "overheatingSavings": 3081
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "134.9811784862178",
   "coolingKwh": "18.633091198467334",
   "overheatingSavings": 1416
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "136.69468731523332",
   "coolingKwh": "16.62789460224922",
   "overheatingSavings": 1335
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "129.62011268982445",
   "coolingKwh": "26.188896778193556",
   "overheatingSavings": 1706
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "138.00285092508113",
   "coolingKwh": "29.958615317440447",
   "overheatingSavings": 1773
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "140.91937787996446",
   "coolingKwh": "25.10295131977833",
   "overheatingSavings": 1603
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "129.6392348414089",
   "coolingKwh": "49.14671176418123",
   "overheatingSavings": 2289
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "142.00088229339224",
   "coolingKwh": "43.15028987163444",
   "overheatingSavings": 2081
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "145.73231441060443",
   "coolingKwh": "34.813098908916004",
   "overheatingSavings": 1850
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "131.63133462254777",
   "coolingKwh": "75.20874553714478",
   "overheatingSavings": 2688
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "77.26836074830966",
   "coolingKwh": "15.952911260984111",
   "overheatingSavings": 1752
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "78.87161684917544",
   "coolingKwh": "13.778408395337776",
   "overheatingSavings": 1616
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "72.44531811308266",
   "coolingKwh": "24.370156945203778",
   "overheatingSavings": 2192
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "80.59818289747813",
   "coolingKwh": "28.12024574971511",
   "overheatingSavings": 2242
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "83.174199615858",
   "coolingKwh": "22.71616338367922",
   "overheatingSavings": 1991
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "73.47386811708589",
   "coolingKwh": "49.453817160828116",
   "overheatingSavings": 2939
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "84.973979351926",
   "coolingKwh": "42.27327865567956",
   "overheatingSavings": 2610
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "88.22343087299856",
   "coolingKwh": "33.13664411586911",
   "overheatingSavings": 2314
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "76.34789064356156",
   "coolingKwh": "77.276596208727",
   "overheatingSavings": 3392
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "49.43001085793678",
   "coolingKwh": "15.565640851458333",
   "overheatingSavings": 2120
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "50.869762028531774",
   "coolingKwh": "13.190684998344555",
   "overheatingSavings": 1955
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "45.12980183071211",
   "coolingKwh": "24.847498194119446",
   "overheatingSavings": 2671
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "52.958024617909444",
   "coolingKwh": "28.42063459449422",
   "overheatingSavings": 2675
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "55.265494878112",
   "coolingKwh": "22.542893440717776",
   "overheatingSavings": 2385
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "46.681023112350445",
   "coolingKwh": "51.24932818059733",
   "overheatingSavings": 3474
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "57.52267845759033",
   "coolingKwh": "43.16620264849611",
   "overheatingSavings": 3065
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "60.397377350699436",
   "coolingKwh": "33.363710787792",
   "overheatingSavings": 2730
 },
 {
   "location": "Madrid",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "49.954784426225885",
   "coolingKwh": "79.98286040515946",
   "overheatingSavings": 3924
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "74.59149872763699",
   "coolingKwh": "6.096098244724655",
   "overheatingSavings": 676
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "75.98704819615389",
   "coolingKwh": "4.9390334723674005",
   "overheatingSavings": 596
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "70.29037379812134",
   "coolingKwh": "10.849404456476687",
   "overheatingSavings": 953
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "76.55323081766555",
   "coolingKwh": "13.267353672503665",
   "overheatingSavings": 1025
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "78.93641759442544",
   "coolingKwh": "10.038239942102622",
   "overheatingSavings": 872
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "69.48767540647744",
   "coolingKwh": "27.68431976526433",
   "overheatingSavings": 1576
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "79.11883824790254",
   "coolingKwh": "22.812319858435224",
   "overheatingSavings": 1356
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "82.30528739562156",
   "coolingKwh": "16.592403349732002",
   "overheatingSavings": 1137
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "70.251697676002",
   "coolingKwh": "49.22815656690034",
   "overheatingSavings": 2037
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "35.80980429561867",
   "coolingKwh": "6.036733412633844",
   "overheatingSavings": 1024
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "37.01968419633844",
   "coolingKwh": "4.614094774783567",
   "overheatingSavings": 858
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "32.11450688966756",
   "coolingKwh": "12.365356706088779",
   "overheatingSavings": 1607
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "38.04224171817133",
   "coolingKwh": "14.891084109887556",
   "overheatingSavings": 1619
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "40.117507837994225",
   "coolingKwh": "10.612450586917245",
   "overheatingSavings": 1327
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "32.10421692027667",
   "coolingKwh": "32.458678087468115",
   "overheatingSavings": 2425
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "40.76848298278133",
   "coolingKwh": "25.736933814078334",
   "overheatingSavings": 1977
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "43.543537919844994",
   "coolingKwh": "18.409411911211333",
   "overheatingSavings": 1665
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "33.294781580494444",
   "coolingKwh": "56.87953889926989",
   "overheatingSavings": 2981
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "17.675554884965443",
   "coolingKwh": "7.2496181312685115",
   "overheatingSavings": 1635
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "18.636594626091334",
   "coolingKwh": "5.379514603121311",
   "overheatingSavings": 1366
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "14.905443725266444",
   "coolingKwh": "15.419166108672778",
   "overheatingSavings": 2346
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "20.23299420692689",
   "coolingKwh": "17.651853964261445",
   "overheatingSavings": 2260
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "21.87698667360089",
   "coolingKwh": "12.643585748832",
   "overheatingSavings": 1942
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "15.814143364731665",
   "coolingKwh": "38.379298759462664",
   "overheatingSavings": 3232
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "23.094769881285444",
   "coolingKwh": "29.493277454329107",
   "overheatingSavings": 2644
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "25.313164377423778",
   "coolingKwh": "21.03922879844222",
   "overheatingSavings": 2224
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "17.332183960442112",
   "coolingKwh": "64.65415598809933",
   "overheatingSavings": 3719
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "116.19560987173332",
   "coolingKwh": "16.255647531764666",
   "overheatingSavings": 1494
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "118.12329699224222",
   "coolingKwh": "14.25903610113289",
   "overheatingSavings": 1387
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "110.23581166755389",
   "coolingKwh": "23.951755547824888",
   "overheatingSavings": 1846
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "119.24673576595667",
   "coolingKwh": "27.91901332368256",
   "overheatingSavings": 1898
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "122.46628033757112",
   "coolingKwh": "22.96505385780044",
   "overheatingSavings": 1710
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "110.20819240495267",
   "coolingKwh": "48.113886189044884",
   "overheatingSavings": 2529
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "123.58598167944555",
   "coolingKwh": "41.90251243469422",
   "overheatingSavings": 2270
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "127.61736786352442",
   "coolingKwh": "33.14222124747",
   "overheatingSavings": 1989
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "112.66552116339777",
   "coolingKwh": "76.12525030354922",
   "overheatingSavings": 2983
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "59.38998157180067",
   "coolingKwh": "14.58466463333711",
   "overheatingSavings": 2004
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "61.147069139455446",
   "coolingKwh": "12.27602063643889",
   "overheatingSavings": 1851
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "54.16392573084044",
   "coolingKwh": "23.42887198012722",
   "overheatingSavings": 2501
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "62.74435106930122",
   "coolingKwh": "27.182385300842334",
   "overheatingSavings": 2523
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "65.56071649655468",
   "coolingKwh": "21.545895346202336",
   "overheatingSavings": 2248
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "55.17092772452944",
   "coolingKwh": "50.03639738232433",
   "overheatingSavings": 3361
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "67.43313749575923",
   "coolingKwh": "42.216874643373",
   "overheatingSavings": 2928
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "70.87445988742489",
   "coolingKwh": "32.41725745285955",
   "overheatingSavings": 2599
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "58.48360954353788",
   "coolingKwh": "79.880893749664",
   "overheatingSavings": 3804
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "32.490694030959105",
   "coolingKwh": "15.219523052292777",
   "overheatingSavings": 2580
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "33.95404890895289",
   "coolingKwh": "12.572694215671333",
   "overheatingSavings": 2366
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "28.137672596007217",
   "coolingKwh": "25.48382494303456",
   "overheatingSavings": 3226
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "36.20591285008833",
   "coolingKwh": "28.877469223894",
   "overheatingSavings": 3147
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "38.610465720754775",
   "coolingKwh": "22.49498326592578",
   "overheatingSavings": 2819
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "29.81012328783511",
   "coolingKwh": "53.78237851179234",
   "overheatingSavings": 3984
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "40.898178939181776",
   "coolingKwh": "44.44507109059044",
   "overheatingSavings": 3524
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "43.88516808327412",
   "coolingKwh": "33.81492315944989",
   "overheatingSavings": 3149
 },
 {
   "location": "Madrid",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "33.31185916877356",
   "coolingKwh": "84.3662174368021",
   "overheatingSavings": 4429
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "80.28725698449888",
   "coolingKwh": "3.671691804803689",
   "overheatingSavings": 485
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "81.92856026838366",
   "coolingKwh": "2.8797652361942",
   "overheatingSavings": 415
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "75.057083255146",
   "coolingKwh": "7.132284802447489",
   "overheatingSavings": 789
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "79.70134380899766",
   "coolingKwh": "8.623467139180134",
   "overheatingSavings": 861
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "82.664156269766",
   "coolingKwh": "6.3015514601335",
   "overheatingSavings": 694
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "70.539885302753",
   "coolingKwh": "18.978439353626666",
   "overheatingSavings": 1453
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "79.55361695218744",
   "coolingKwh": "15.029334286199889",
   "overheatingSavings": 1202
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "83.66914580016966",
   "coolingKwh": "10.684716214477278",
   "overheatingSavings": 955
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "67.35013005504544",
   "coolingKwh": "34.99462013259245",
   "overheatingSavings": 1982
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "42.61181635979944",
   "coolingKwh": "3.8048110617991893",
   "overheatingSavings": 771
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "44.04238302089678",
   "coolingKwh": "2.7509625956569557",
   "overheatingSavings": 604
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "38.181668590985225",
   "coolingKwh": "8.487850865390044",
   "overheatingSavings": 1339
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "42.70593590615955",
   "coolingKwh": "10.148392916388955",
   "overheatingSavings": 1391
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "45.230701877063666",
   "coolingKwh": "7.071948599288266",
   "overheatingSavings": 1107
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "35.486798484849004",
   "coolingKwh": "24.133401104553222",
   "overheatingSavings": 2229
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "43.264366289959774",
   "coolingKwh": "18.335234671716776",
   "overheatingSavings": 1871
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "46.65478276186889",
   "coolingKwh": "12.512535905781112",
   "overheatingSavings": 1497
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "34.03963591158711",
   "coolingKwh": "43.11790389298422",
   "overheatingSavings": 2738
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "24.88123572932589",
   "coolingKwh": "4.6541391514704555",
   "overheatingSavings": 1213
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "26.066816048596444",
   "coolingKwh": "3.2638846206693",
   "overheatingSavings": 938
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "21.439975230037223",
   "coolingKwh": "10.663696142369789",
   "overheatingSavings": 1955
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "25.682593508532445",
   "coolingKwh": "12.402861865086889",
   "overheatingSavings": 1967
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "27.706985115135",
   "coolingKwh": "8.510618937261734",
   "overheatingSavings": 1588
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "20.17537412808022",
   "coolingKwh": "29.01586091948289",
   "overheatingSavings": 2740
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "26.828482284929557",
   "coolingKwh": "21.919422469685777",
   "overheatingSavings": 2358
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "29.508274785350334",
   "coolingKwh": "15.020207691673557",
   "overheatingSavings": 2037
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "19.58994027231289",
   "coolingKwh": "50.74284398426467",
   "overheatingSavings": 3261
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "128.62630695839334",
   "coolingKwh": "14.27899343670489",
   "overheatingSavings": 1432
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "130.94401384025778",
   "coolingKwh": "12.681768480061557",
   "overheatingSavings": 1335
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "121.24138771440221",
   "coolingKwh": "20.472762533607334",
   "overheatingSavings": 1775
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "127.87457182298887",
   "coolingKwh": "23.184502897649445",
   "overheatingSavings": 1833
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "132.0477225883289",
   "coolingKwh": "19.199599735903448",
   "overheatingSavings": 1649
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "115.19113365525666",
   "coolingKwh": "38.84743162436811",
   "overheatingSavings": 2352
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "127.85377264340445",
   "coolingKwh": "33.47668195395156",
   "overheatingSavings": 2129
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "133.56789560079667",
   "coolingKwh": "26.758989960090332",
   "overheatingSavings": 1917
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "111.03369663530688",
   "coolingKwh": "60.11711452524967",
   "overheatingSavings": 2839
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "73.27047782278255",
   "coolingKwh": "13.253446198520221",
   "overheatingSavings": 1857
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "75.30148221100222",
   "coolingKwh": "11.34768000020978",
   "overheatingSavings": 1734
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "66.87160291639589",
   "coolingKwh": "20.904750838061446",
   "overheatingSavings": 2318
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "73.44970646080922",
   "coolingKwh": "23.690893791948",
   "overheatingSavings": 2350
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "77.075339123096",
   "coolingKwh": "18.913556521116778",
   "overheatingSavings": 2124
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "62.77672584221034",
   "coolingKwh": "42.12151848116022",
   "overheatingSavings": 2987
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "74.28358364011856",
   "coolingKwh": "35.216696909869",
   "overheatingSavings": 2696
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "79.1862840096469",
   "coolingKwh": "27.438291780550337",
   "overheatingSavings": 2423
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "60.48859944854934",
   "coolingKwh": "66.55523555892789",
   "overheatingSavings": 3481
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "46.542792820894896",
   "coolingKwh": "13.804248403070666",
   "overheatingSavings": 2257
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "48.345692981151224",
   "coolingKwh": "11.508421854239668",
   "overheatingSavings": 2088
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "41.018141860484334",
   "coolingKwh": "22.62174761000178",
   "overheatingSavings": 2771
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "47.466523059598",
   "coolingKwh": "25.353906630088222",
   "overheatingSavings": 2770
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "50.61380945019889",
   "coolingKwh": "20.019485708132112",
   "overheatingSavings": 2526
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "38.60653152056722",
   "coolingKwh": "46.358926332849116",
   "overheatingSavings": 3495
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "48.950568069672215",
   "coolingKwh": "37.865002664661894",
   "overheatingSavings": 3109
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "53.178596990587444",
   "coolingKwh": "29.17240615111756",
   "overheatingSavings": 2809
 },
 {
   "location": "Madrid",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "37.734908005788775",
   "coolingKwh": "72.928311742804",
   "overheatingSavings": 4043
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "70.78089154507045",
   "coolingKwh": "3.879677781091089",
   "overheatingSavings": 583
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "72.54115086731788",
   "coolingKwh": "2.964891316919933",
   "overheatingSavings": 480
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "65.20115461380533",
   "coolingKwh": "7.9384185939234",
   "overheatingSavings": 980
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "70.26863992630444",
   "coolingKwh": "9.645273219401755",
   "overheatingSavings": 1063
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "73.4257074988489",
   "coolingKwh": "6.9425002956008335",
   "overheatingSavings": 856
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "60.739171123179666",
   "coolingKwh": "21.712397807006777",
   "overheatingSavings": 1773
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "70.29892000399266",
   "coolingKwh": "17.00623078025189",
   "overheatingSavings": 1440
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "74.61843877753334",
   "coolingKwh": "12.011875912528222",
   "overheatingSavings": 1161
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "57.89003183524989",
   "coolingKwh": "39.78833986997845",
   "overheatingSavings": 2306
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "33.40765422960523",
   "coolingKwh": "4.7951018903138",
   "overheatingSavings": 1128
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "34.89459509505",
   "coolingKwh": "3.408409715840211",
   "overheatingSavings": 887
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "28.946012111011665",
   "coolingKwh": "10.672272620270476",
   "overheatingSavings": 1818
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "33.84379088582267",
   "coolingKwh": "12.533299798249999",
   "overheatingSavings": 1839
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "36.37350035139044",
   "coolingKwh": "8.751213780938178",
   "overheatingSavings": 1513
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "26.960640831601335",
   "coolingKwh": "29.255678317498333",
   "overheatingSavings": 2635
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "34.80926353840645",
   "coolingKwh": "22.25525140586811",
   "overheatingSavings": 2264
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "38.155203937066666",
   "coolingKwh": "15.271442496991446",
   "overheatingSavings": 1909
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "26.09053018914278",
   "coolingKwh": "51.265197648012105",
   "overheatingSavings": 3148
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "16.341209849488223",
   "coolingKwh": "6.670989862942034",
   "overheatingSavings": 1875
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "17.370882758311332",
   "coolingKwh": "4.822249020011478",
   "overheatingSavings": 1643
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "13.325376140016557",
   "coolingKwh": "14.662004507018",
   "overheatingSavings": 2487
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "17.764876337413334",
   "coolingKwh": "16.439855632849223",
   "overheatingSavings": 2446
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "19.56263889485822",
   "coolingKwh": "11.471731736511778",
   "overheatingSavings": 2133
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "12.685558706656444",
   "coolingKwh": "36.74130349287311",
   "overheatingSavings": 3293
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "19.335589476209886",
   "coolingKwh": "27.49519135372778",
   "overheatingSavings": 2809
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "21.817726477616446",
   "coolingKwh": "19.27499604230422",
   "overheatingSavings": 2466
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "12.598769988511",
   "coolingKwh": "62.215604144814",
   "overheatingSavings": 3920
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "112.43278010418891",
   "coolingKwh": "13.953428378675666",
   "overheatingSavings": 1578
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "114.85943850788665",
   "coolingKwh": "12.221607169683779",
   "overheatingSavings": 1471
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "104.78288606336656",
   "coolingKwh": "20.699646177939556",
   "overheatingSavings": 1918
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "112.07238093625",
   "coolingKwh": "23.72891672160722",
   "overheatingSavings": 1981
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "116.36280258318",
   "coolingKwh": "19.360250727875442",
   "overheatingSavings": 1797
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "99.0171160888171",
   "coolingKwh": "41.027298020442664",
   "overheatingSavings": 2579
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "112.50649261459999",
   "coolingKwh": "35.12274919402833",
   "overheatingSavings": 2339
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "118.36842303181112",
   "coolingKwh": "27.703067875382",
   "overheatingSavings": 2074
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "95.41840714973134",
   "coolingKwh": "64.54211295097045",
   "overheatingSavings": 3066
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "57.56460151036523",
   "coolingKwh": "13.675242021139333",
   "overheatingSavings": 2084
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "59.67433744455178",
   "coolingKwh": "11.50298275296389",
   "overheatingSavings": 1946
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "51.105063193569",
   "coolingKwh": "22.471660658315113",
   "overheatingSavings": 2640
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "58.32180881526745",
   "coolingKwh": "25.522342898061332",
   "overheatingSavings": 2647
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "61.96101536568566",
   "coolingKwh": "20.105412211805778",
   "overheatingSavings": 2378
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "47.855924814172",
   "coolingKwh": "46.53683793922",
   "overheatingSavings": 3339
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "59.78750557464078",
   "coolingKwh": "38.45422231618444",
   "overheatingSavings": 2980
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "64.66398888317089",
   "coolingKwh": "29.664837231856776",
   "overheatingSavings": 2695
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "46.610722099431335",
   "coolingKwh": "73.79089077620034",
   "overheatingSavings": 3882
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "31.436647441935335",
   "coolingKwh": "15.206342898236556",
   "overheatingSavings": 2683
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "33.188857191818556",
   "coolingKwh": "12.43544046670889",
   "overheatingSavings": 2401
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "26.34180839196489",
   "coolingKwh": "25.62246863682311",
   "overheatingSavings": 3242
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "33.22049802629056",
   "coolingKwh": "28.48222008583811",
   "overheatingSavings": 3181
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "36.16936133229533",
   "coolingKwh": "22.290337500394",
   "overheatingSavings": 2951
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "25.50849567689822",
   "coolingKwh": "53.05720425772322",
   "overheatingSavings": 4087
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "35.51121719367456",
   "coolingKwh": "42.742555405590224",
   "overheatingSavings": 3513
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "39.42000086368744",
   "coolingKwh": "32.60620750183066",
   "overheatingSavings": 3165
 },
 {
   "location": "Madrid",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "25.663590805624",
   "coolingKwh": "82.987435350681",
   "overheatingSavings": 4609
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "211.19928576258332",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "212.78543175690555",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "206.07050129144332",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "213.34405515366555",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "216.22849639445334",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "204.3076254317622",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "215.99861049168223",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "219.98252418183444",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "203.82437159567334",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "146.83791237779556",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "148.43192735113442",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "141.7657386551378",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "148.95660069063777",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "151.77062968472666",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "140.46204518917446",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "151.73923757079334",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "155.53828451504336",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "140.5841756605289",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "114.93291304624776",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "116.50683307025777",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "110.0195980331999",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "117.15218044199001",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "119.86381712089889",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "109.10534762607722",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "120.10634286548334",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "123.7040809881089",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "109.61141875030067",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "317.8421161188022",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "320.11411448515446",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "310.3979221845878",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "319.32690926730555",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "323.5204108806278",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "305.9307653161867",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "321.5113785022189",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "327.37325156958775",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "303.45074011439",
   "coolingKwh": "0.15561849247129222",
   "overheatingSavings": 25
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "230.89683069716222",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "233.29587170587777",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "223.13430207388666",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "231.9412057065322",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "236.2493835781033",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "218.47126753210998",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "233.97338017720332",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "239.87819902356333",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "216.30768712372998",
   "coolingKwh": "0.10936684404684545",
   "overheatingSavings": 21
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "187.68164458680667",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "190.16399922454892",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "179.66620354535445",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "188.37655624184222",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "192.77476818712776",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "174.9215800801111",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "190.32828295617557",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "196.24125066244665",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "173.12136810077666",
   "coolingKwh": "0.10824734916633279",
   "overheatingSavings": 22
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "161.47597002792224",
   "coolingKwh": "0.016600809215487778",
   "overheatingSavings": 4
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "162.8702618281589",
   "coolingKwh": "0.0038483750649012777",
   "overheatingSavings": 2
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "157.00214487937222",
   "coolingKwh": "0.104036668703076",
   "overheatingSavings": 12
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "166.4824875319767",
   "coolingKwh": "0.1786458959857689",
   "overheatingSavings": 24
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "168.98034537804446",
   "coolingKwh": "0.08524694729208633",
   "overheatingSavings": 11
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "158.78851383935776",
   "coolingKwh": "1.6831199755904556",
   "overheatingSavings": 157
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "171.88358839407",
   "coolingKwh": "0.9928290458757145",
   "overheatingSavings": 102
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "175.28687880605668",
   "coolingKwh": "0.37388474629207447",
   "overheatingSavings": 51
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "161.78133802322887",
   "coolingKwh": "6.199924659761834",
   "overheatingSavings": 388
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "96.75969368873967",
   "coolingKwh": "0.013918322532516778",
   "overheatingSavings": 4
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "98.04523621122078",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "92.74690422245067",
   "coolingKwh": "0.1482474447054622",
   "overheatingSavings": 23
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "102.02469959284645",
   "coolingKwh": "0.28232938494844",
   "overheatingSavings": 45
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "104.28664163841455",
   "coolingKwh": "0.09855207140581668",
   "overheatingSavings": 13
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "95.20767307220156",
   "coolingKwh": "2.8504841303908113",
   "overheatingSavings": 288
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "107.62135876040068",
   "coolingKwh": "1.5589416831321445",
   "overheatingSavings": 168
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "110.69604320101223",
   "coolingKwh": "0.6079335275045678",
   "overheatingSavings": 85
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "98.70305476991922",
   "coolingKwh": "9.553635021705878",
   "overheatingSavings": 659
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "65.14968486148867",
   "coolingKwh": "0.01680546063334589",
   "overheatingSavings": 5
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "66.32707319189645",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "61.49845005016366",
   "coolingKwh": "0.2976773340018411",
   "overheatingSavings": 58
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "70.61436999877768",
   "coolingKwh": "0.5347270636615422",
   "overheatingSavings": 93
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "72.69114863001322",
   "coolingKwh": "0.15508437700793223",
   "overheatingSavings": 31
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "64.62692065710243",
   "coolingKwh": "4.499453373052923",
   "overheatingSavings": 500
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "76.37423150642489",
   "coolingKwh": "2.3752967399279665",
   "overheatingSavings": 284
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "79.18125232890422",
   "coolingKwh": "0.94437962403942",
   "overheatingSavings": 130
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "68.60620146386854",
   "coolingKwh": "12.890395232055889",
   "overheatingSavings": 981
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "227.8982931980789",
   "coolingKwh": "0.7671377271837889",
   "overheatingSavings": 121
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "229.8038587572356",
   "coolingKwh": "0.5252695144248289",
   "overheatingSavings": 90
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "221.8662109615189",
   "coolingKwh": "2.008659375752178",
   "overheatingSavings": 241
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "234.31925355433557",
   "coolingKwh": "2.7703082422795107",
   "overheatingSavings": 296
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "237.6367142274511",
   "coolingKwh": "1.7892640384131333",
   "overheatingSavings": 212
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "224.42624719761224",
   "coolingKwh": "8.28703178953829",
   "overheatingSavings": 626
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "241.52083368399445",
   "coolingKwh": "6.207021357494444",
   "overheatingSavings": 493
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "245.91029528440555",
   "coolingKwh": "3.9029041740232664",
   "overheatingSavings": 370
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "228.98431424582",
   "coolingKwh": "18.054765665682332",
   "overheatingSavings": 963
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "140.2260029120989",
   "coolingKwh": "0.6043672196344633",
   "overheatingSavings": 127
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "142.0674448089978",
   "coolingKwh": "0.33993636416715",
   "overheatingSavings": 81
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "134.4872363598211",
   "coolingKwh": "2.1491443250729665",
   "overheatingSavings": 329
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "146.9253657867389",
   "coolingKwh": "3.0525300701603557",
   "overheatingSavings": 399
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "150.04250416631444",
   "coolingKwh": "1.757363603044589",
   "overheatingSavings": 265
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "137.99680995243332",
   "coolingKwh": "10.219930094209934",
   "overheatingSavings": 903
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "154.57668566782667",
   "coolingKwh": "7.423443331730945",
   "overheatingSavings": 703
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "158.55502071746668",
   "coolingKwh": "4.37707978888561",
   "overheatingSavings": 490
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "143.66952044482224",
   "coolingKwh": "22.189758751672443",
   "overheatingSavings": 1373
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "96.82399099451143",
   "coolingKwh": "0.6722411382993756",
   "overheatingSavings": 163
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "98.56863370024055",
   "coolingKwh": "0.34678036442253557",
   "overheatingSavings": 99
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "91.48861904380311",
   "coolingKwh": "2.663037224735189",
   "overheatingSavings": 475
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "103.77139680545055",
   "coolingKwh": "3.589190130830155",
   "overheatingSavings": 537
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "106.67196085420557",
   "coolingKwh": "2.0189680545287776",
   "overheatingSavings": 355
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "95.67800308713323",
   "coolingKwh": "12.165505227376777",
   "overheatingSavings": 1237
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "111.66337459617002",
   "coolingKwh": "8.661274883077066",
   "overheatingSavings": 924
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "115.33096662294666",
   "coolingKwh": "5.079950488030311",
   "overheatingSavings": 661
 },
 {
   "location": "Nancy",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "101.92247724469289",
   "coolingKwh": "25.466754383764997",
   "overheatingSavings": 1827
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "138.2389607066389",
   "coolingKwh": "0.012773841524502446",
   "overheatingSavings": 4
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "139.74558542732558",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "133.47759316138666",
   "coolingKwh": "0.10409545833643344",
   "overheatingSavings": 14
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "143.90366663821223",
   "coolingKwh": "0.18915565419144112",
   "overheatingSavings": 27
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "146.54877954503777",
   "coolingKwh": "0.08408880785727743",
   "overheatingSavings": 13
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "135.90598270414998",
   "coolingKwh": "1.9711979380525666",
   "overheatingSavings": 189
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "150.04504724602222",
   "coolingKwh": "1.1456295691517333",
   "overheatingSavings": 126
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "153.60487105690333",
   "coolingKwh": "0.3984291300972033",
   "overheatingSavings": 53
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "139.59870790981444",
   "coolingKwh": "7.258882931905033",
   "overheatingSavings": 478
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "74.310901002978",
   "coolingKwh": "0.01983233254046711",
   "overheatingSavings": 6
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "75.65651485111766",
   "coolingKwh": "0.0008695892415839356",
   "overheatingSavings": 1
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "70.16622074105521",
   "coolingKwh": "0.23787330904563558",
   "overheatingSavings": 52
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "80.34614676984711",
   "coolingKwh": "0.44234875564609777",
   "overheatingSavings": 86
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "82.66972356458555",
   "coolingKwh": "0.13750209185844778",
   "overheatingSavings": 25
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "73.59781868783978",
   "coolingKwh": "4.137848215089545",
   "overheatingSavings": 487
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "86.74502116694089",
   "coolingKwh": "2.1959135971357",
   "overheatingSavings": 275
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "89.87704017932712",
   "coolingKwh": "0.8766053344542722",
   "overheatingSavings": 133
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "78.07592870887122",
   "coolingKwh": "12.445164552073779",
   "overheatingSavings": 959
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "43.521994471765666",
   "coolingKwh": "0.04832536146062522",
   "overheatingSavings": 15
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "44.60702994241078",
   "coolingKwh": "0.01138067534107511",
   "overheatingSavings": 6
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "40.32562329421811",
   "coolingKwh": "0.808383068409811",
   "overheatingSavings": 198
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "49.99531936124711",
   "coolingKwh": "1.1016426125154601",
   "overheatingSavings": 213
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "51.855852029270224",
   "coolingKwh": "0.3506585251121178",
   "overheatingSavings": 87
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "44.72026351551378",
   "coolingKwh": "7.54143302309609",
   "overheatingSavings": 1031
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "56.63382657372277",
   "coolingKwh": "4.011184131472589",
   "overheatingSavings": 565
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "59.18242314736856",
   "coolingKwh": "1.6737996592658222",
   "overheatingSavings": 284
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "49.672985855930996",
   "coolingKwh": "17.951080524570223",
   "overheatingSavings": 1606
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "194.58881879264445",
   "coolingKwh": "0.5627287033375311",
   "overheatingSavings": 118
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "196.66016650095779",
   "coolingKwh": "0.3426700691808722",
   "overheatingSavings": 74
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "188.07235008921",
   "coolingKwh": "1.7823578042668",
   "overheatingSavings": 256
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "201.87901010193332",
   "coolingKwh": "2.561636785001744",
   "overheatingSavings": 313
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "205.43995848618",
   "coolingKwh": "1.5715626002400556",
   "overheatingSavings": 229
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "191.42359464437334",
   "coolingKwh": "8.4717009404345",
   "overheatingSavings": 707
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "210.13876343385334",
   "coolingKwh": "6.2498734889464",
   "overheatingSavings": 551
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "214.7803258679911",
   "coolingKwh": "3.8030123819796",
   "overheatingSavings": 401
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "197.18436083251444",
   "coolingKwh": "19.37720807114411",
   "overheatingSavings": 1126
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "107.53252191680588",
   "coolingKwh": "0.5950765389306366",
   "overheatingSavings": 169
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "109.46238946001944",
   "coolingKwh": "0.32394371895838997",
   "overheatingSavings": 90
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "101.58592330386233",
   "coolingKwh": "2.3552983408556445",
   "overheatingSavings": 453
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "115.17882333355001",
   "coolingKwh": "3.2321190300290996",
   "overheatingSavings": 517
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "118.43751258305889",
   "coolingKwh": "1.814963328966867",
   "overheatingSavings": 340
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "106.08797035875232",
   "coolingKwh": "11.410521508353668",
   "overheatingSavings": 1214
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "123.86556646589224",
   "coolingKwh": "8.060740770891233",
   "overheatingSavings": 893
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "127.95244692689889",
   "coolingKwh": "4.616545013771289",
   "overheatingSavings": 607
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "112.9628214757011",
   "coolingKwh": "24.807619213301443",
   "overheatingSavings": 1803
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "65.11058955414077",
   "coolingKwh": "1.0325146580296256",
   "overheatingSavings": 348
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "66.75314306960655",
   "coolingKwh": "0.5402516981553389",
   "overheatingSavings": 213
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "60.13039618994545",
   "coolingKwh": "3.9193830889064674",
   "overheatingSavings": 974
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "73.30161965808743",
   "coolingKwh": "4.7012991190343",
   "overheatingSavings": 939
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "76.11516785159701",
   "coolingKwh": "2.6565351712107",
   "overheatingSavings": 619
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "65.601011941053",
   "coolingKwh": "15.203430463250669",
   "overheatingSavings": 1957
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "82.16196201238277",
   "coolingKwh": "10.391913566697822",
   "overheatingSavings": 1381
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "85.76598815368445",
   "coolingKwh": "6.143655785550333",
   "overheatingSavings": 992
 },
 {
   "location": "Nancy",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "72.87036068270612",
   "coolingKwh": "29.89157796846389",
   "overheatingSavings": 2538
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "156.74570752482555",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "158.4767699266889",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "151.17437019194668",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "159.54957570264",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "162.70644136728336",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "149.87125634322334",
   "coolingKwh": "0.11752478793592777",
   "overheatingSavings": 14
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "162.76976868280556",
   "coolingKwh": "0.04428943150378322",
   "overheatingSavings": 9
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "167.1131155966322",
   "coolingKwh": "0.0007106047532537223",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "149.8218758225889",
   "coolingKwh": "1.2284167099455112",
   "overheatingSavings": 133
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "93.95352023459289",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "95.4890992649279",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "89.12822048918923",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "97.45252324146456",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "100.187311367639",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "89.49757294969645",
   "coolingKwh": "0.4879931095631423",
   "overheatingSavings": 78
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "101.35375894217921",
   "coolingKwh": "0.1386556006192689",
   "overheatingSavings": 27
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "105.04595771351033",
   "coolingKwh": "0.010458032825250645",
   "overheatingSavings": 4
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "91.00809776175488",
   "coolingKwh": "3.618827343254711",
   "overheatingSavings": 453
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "63.378532307788994",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "64.71263509330066",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "59.34762527510667",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "67.54982170260512",
   "coolingKwh": "0.003236505923155333",
   "overheatingSavings": 2
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "69.84373037820689",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "60.89308123958078",
   "coolingKwh": "1.4108940237931111",
   "overheatingSavings": 276
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "71.98586706338823",
   "coolingKwh": "0.43792989633652113",
   "overheatingSavings": 84
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "75.104666983651",
   "coolingKwh": "0.05098543685283033",
   "overheatingSavings": 13
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "63.233164457563994",
   "coolingKwh": "7.209665747234122",
   "overheatingSavings": 1026
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "223.3923984437589",
   "coolingKwh": "0.04855012395687822",
   "overheatingSavings": 8
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "225.89803748299778",
   "coolingKwh": "0.030299304306833336",
   "overheatingSavings": 7
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "215.34590891596224",
   "coolingKwh": "0.21720833895816444",
   "overheatingSavings": 44
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "226.34441306300332",
   "coolingKwh": "0.36359722760597446",
   "overheatingSavings": 63
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "230.87807581025112",
   "coolingKwh": "0.16083937217274002",
   "overheatingSavings": 31
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "212.3875242032367",
   "coolingKwh": "2.3980268652107664",
   "overheatingSavings": 295
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "229.97803683035002",
   "coolingKwh": "1.474219341307689",
   "overheatingSavings": 206
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "236.2053547375611",
   "coolingKwh": "0.6355044515545267",
   "overheatingSavings": 101
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "211.5651713650189",
   "coolingKwh": "7.360539753426289",
   "overheatingSavings": 684
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "137.02312671902445",
   "coolingKwh": "0.03516246268474811",
   "overheatingSavings": 14
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "139.29946069660778",
   "coolingKwh": "0.01333023086806811",
   "overheatingSavings": 5
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "129.93595444067776",
   "coolingKwh": "0.3698779451323067",
   "overheatingSavings": 95
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "140.95433698879444",
   "coolingKwh": "0.6178462105579656",
   "overheatingSavings": 141
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "144.9017489370511",
   "coolingKwh": "0.2204699895357378",
   "overheatingSavings": 48
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "129.3904331781111",
   "coolingKwh": "4.380838864873766",
   "overheatingSavings": 703
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "145.67486875459667",
   "coolingKwh": "2.4544241759040664",
   "overheatingSavings": 411
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "150.94777300773222",
   "coolingKwh": "1.0615727155017642",
   "overheatingSavings": 211
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "130.54512434136333",
   "coolingKwh": "12.269925954974445",
   "overheatingSavings": 1370
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "94.7835636365019",
   "coolingKwh": "0.05720904284418155",
   "overheatingSavings": 24
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "96.77923986168788",
   "coolingKwh": "0.009880307939366744",
   "overheatingSavings": 5
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "88.72118298716589",
   "coolingKwh": "0.8439013092518922",
   "overheatingSavings": 256
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "99.6677368256",
   "coolingKwh": "1.1830442480060557",
   "overheatingSavings": 318
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "103.09351819787699",
   "coolingKwh": "0.42802658454570003",
   "overheatingSavings": 127
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "89.60397949804911",
   "coolingKwh": "7.243034677212377",
   "overheatingSavings": 1326
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "105.03594529434233",
   "coolingKwh": "4.036055988861589",
   "overheatingSavings": 796
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "109.68856912447966",
   "coolingKwh": "1.791009264335678",
   "overheatingSavings": 418
 },
 {
   "location": "Nancy",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "92.04912694848278",
   "coolingKwh": "17.173608762888",
   "overheatingSavings": 2052
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "135.54583141231555",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "137.35066339921667",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "129.8626398716667",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "139.17815086149778",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "142.3798383306278",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "129.44735782459335",
   "coolingKwh": "0.2215883768265622",
   "overheatingSavings": 33
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "143.24948513500223",
   "coolingKwh": "0.08083306042229023",
   "overheatingSavings": 13
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "147.64035425891333",
   "coolingKwh": "0.007406275215013935",
   "overheatingSavings": 3
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "130.4242040430789",
   "coolingKwh": "2.1768739143487665",
   "overheatingSavings": 244
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "73.35191904329244",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "74.88906993802722",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "68.76117365070611",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "77.94701292056756",
   "coolingKwh": "0.0068472421862521326",
   "overheatingSavings": 4
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "80.55160202717789",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "70.47350574544244",
   "coolingKwh": "1.4597525843257333",
   "overheatingSavings": 278
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "82.92571182389433",
   "coolingKwh": "0.4375892017657",
   "overheatingSavings": 81
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "86.4031523704581",
   "coolingKwh": "0.06038247265064155",
   "overheatingSavings": 16
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "73.13867221048278",
   "coolingKwh": "7.246871279759845",
   "overheatingSavings": 995
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "43.601354058724446",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "44.80753753045733",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "40.02681989479956",
   "coolingKwh": "0.12430202931319777",
   "overheatingSavings": 49
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "48.96051498022156",
   "coolingKwh": "0.20756177285660002",
   "overheatingSavings": 68
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "51.03718243178656",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "43.059733399300555",
   "coolingKwh": "4.895356279446511",
   "overheatingSavings": 1174
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "54.44081660188477",
   "coolingKwh": "1.6820099036175888",
   "overheatingSavings": 421
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "57.284442588717106",
   "coolingKwh": "0.39356068241543224",
   "overheatingSavings": 100
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "46.491781930752666",
   "coolingKwh": "14.099093908169777",
   "overheatingSavings": 2022
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "192.3299615992767",
   "coolingKwh": "0.046898563715195664",
   "overheatingSavings": 10
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "194.92591947761002",
   "coolingKwh": "0.026148195321125108",
   "overheatingSavings": 7
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "184.1241859596589",
   "coolingKwh": "0.2963341964808189",
   "overheatingSavings": 60
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "196.41974524652443",
   "coolingKwh": "0.51146333213827",
   "overheatingSavings": 97
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "201.02347220288556",
   "coolingKwh": "0.21820161464982557",
   "overheatingSavings": 44
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "182.56296184574998",
   "coolingKwh": "3.3107004582475006",
   "overheatingSavings": 448
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "201.25265298718222",
   "coolingKwh": "2.0543092384335555",
   "overheatingSavings": 292
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "207.5240855820011",
   "coolingKwh": "0.9105819004920112",
   "overheatingSavings": 156
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "183.25366966039888",
   "coolingKwh": "9.800408978777556",
   "overheatingSavings": 952
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "106.78842790413222",
   "coolingKwh": "0.07403068001173478",
   "overheatingSavings": 26
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "109.01943621416989",
   "coolingKwh": "0.01600263976963778",
   "overheatingSavings": 8
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "100.08940205421989",
   "coolingKwh": "0.9488230274600646",
   "overheatingSavings": 279
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "112.35079789222223",
   "coolingKwh": "1.3254444375010668",
   "overheatingSavings": 337
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "116.1297420113122",
   "coolingKwh": "0.5020041593985789",
   "overheatingSavings": 152
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "101.36326060431833",
   "coolingKwh": "7.3737715260292225",
   "overheatingSavings": 1287
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "118.5200755705589",
   "coolingKwh": "4.210579914938378",
   "overheatingSavings": 780
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "123.57749856334557",
   "coolingKwh": "1.9782593197192553",
   "overheatingSavings": 437
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "104.26669406806144",
   "coolingKwh": "17.567575502957",
   "overheatingSavings": 2008
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "65.602080312187",
   "coolingKwh": "0.3236174278787611",
   "overheatingSavings": 169
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "67.44028303201878",
   "coolingKwh": "0.07785712375253688",
   "overheatingSavings": 38
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "59.983872988734",
   "coolingKwh": "2.644269827370389",
   "overheatingSavings": 990
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "72.078931573272",
   "coolingKwh": "3.035565475086889",
   "overheatingSavings": 954
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "75.30227453637077",
   "coolingKwh": "1.3820969765362667",
   "overheatingSavings": 524
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "63.32019521112633",
   "coolingKwh": "12.758531638519667",
   "overheatingSavings": 2288
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "78.96158381316101",
   "coolingKwh": "7.566715193571755",
   "overheatingSavings": 1604
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "83.26477446144222",
   "coolingKwh": "3.8634883624905227",
   "overheatingSavings": 1010
 },
 {
   "location": "Nancy",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "67.80610206912478",
   "coolingKwh": "25.000849963586337",
   "overheatingSavings": 2650
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "270.4451624257711",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "272.14927125752666",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "264.9054150893666",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "273.4489571973011",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "276.5770650933689",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "263.5171498471167",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "276.93309437099003",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "281.29477221582556",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "263.4626422309111",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "193.01425455257223",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "194.79575892830113",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "187.30104191332555",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "195.73561992983778",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "198.9098829301611",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "185.9546914984878",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "199.17353823803444",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "203.48833534163666",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "186.2281334674511",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "154.5875946326711",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "156.44616747620447",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "148.74750713018557",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "157.10432413840002",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "160.3045189951811",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "147.4328590904389",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "160.53527777957223",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "164.7996801554811",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "148.0187725815267",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "383.50767555423",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "385.73082634757446",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "376.1340156742522",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "386.42393145328884",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "390.5995758908611",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "373.03696461759114",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "389.9852637266622",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "395.8148461419222",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "371.5376593680967",
   "coolingKwh": "0.054578270935834",
   "overheatingSavings": 9
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "283.2359776412711",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "285.5545824339811",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "275.55421425531335",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "285.6676311818433",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "290.00094444919444",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "271.88668854573336",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "288.88155828734443",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "294.8829123422911",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "270.23736713626886",
   "coolingKwh": "0.012364736219728778",
   "overheatingSavings": 4
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "233.36947654047668",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "235.74485544938557",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "225.48524509287",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "235.47712368780225",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "239.9181041717511",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "221.4574902921056",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "238.46726537957224",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "244.57344370040778",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "219.75104735887223",
   "coolingKwh": "0.0005540468968410667",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "201.18212096299334",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "202.64736266081556",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "196.48849056517446",
   "coolingKwh": "0.05807331227688111",
   "overheatingSavings": 10
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "207.8024451899333",
   "coolingKwh": "0.1040504948851409",
   "overheatingSavings": 11
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "210.42529254503776",
   "coolingKwh": "0.045421358400146786",
   "overheatingSavings": 7
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "199.76038839999336",
   "coolingKwh": "1.0215397495912624",
   "overheatingSavings": 95
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "214.78495111073778",
   "coolingKwh": "0.5634610784958388",
   "overheatingSavings": 68
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "218.37127460896443",
   "coolingKwh": "0.2030381559907389",
   "overheatingSavings": 25
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "204.17570740575889",
   "coolingKwh": "4.300498561090155",
   "overheatingSavings": 276
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "123.36659282666",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "124.74022498357333",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "119.04050074353444",
   "coolingKwh": "0.049670998600998106",
   "overheatingSavings": 10
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "130.22028828752002",
   "coolingKwh": "0.10882125102412299",
   "overheatingSavings": 16
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "132.6502305582367",
   "coolingKwh": "0.029684513730685",
   "overheatingSavings": 7
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "122.90226660461778",
   "coolingKwh": "1.6164075603580776",
   "overheatingSavings": 164
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "137.40674808586888",
   "coolingKwh": "0.8423136243919643",
   "overheatingSavings": 104
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "140.70707296833334",
   "coolingKwh": "0.25305314667778883",
   "overheatingSavings": 37
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "127.80675790583334",
   "coolingKwh": "6.3947064901689",
   "overheatingSavings": 445
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "84.97003535753957",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "86.229951184271",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "81.04001186092366",
   "coolingKwh": "0.07389533501746",
   "overheatingSavings": 17
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "92.07793027830468",
   "coolingKwh": "0.18632058512332336",
   "overheatingSavings": 39
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "94.30755332430711",
   "coolingKwh": "0.034513984181309",
   "overheatingSavings": 9
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "85.502230441166",
   "coolingKwh": "2.6056200569421333",
   "overheatingSavings": 286
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "99.43515839919768",
   "coolingKwh": "1.2819519504585333",
   "overheatingSavings": 154
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "102.46924108247578",
   "coolingKwh": "0.40794256604453666",
   "overheatingSavings": 64
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "90.80672707349378",
   "coolingKwh": "8.713373796807678",
   "overheatingSavings": 671
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "271.36712135458663",
   "coolingKwh": "0.4736856174473",
   "overheatingSavings": 75
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "273.3270010846278",
   "coolingKwh": "0.3356420498486056",
   "overheatingSavings": 57
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "265.12183608352666",
   "coolingKwh": "1.259803611510411",
   "overheatingSavings": 148
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "279.4812111712878",
   "coolingKwh": "1.7780602040309665",
   "overheatingSavings": 184
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "282.9386570544144",
   "coolingKwh": "1.1259066296580333",
   "overheatingSavings": 128
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "269.11312041916",
   "coolingKwh": "5.798386782703878",
   "overheatingSavings": 442
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "288.29462523606",
   "coolingKwh": "4.311134010924878",
   "overheatingSavings": 344
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "292.8848995109344",
   "coolingKwh": "2.576912707126967",
   "overheatingSavings": 237
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "275.0865943484667",
   "coolingKwh": "13.249827738524333",
   "overheatingSavings": 731
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "169.71650277188556",
   "coolingKwh": "0.27703369262198446",
   "overheatingSavings": 64
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "171.63045898934666",
   "coolingKwh": "0.1478655369258789",
   "overheatingSavings": 36
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "163.76972339373",
   "coolingKwh": "1.2114110360883332",
   "overheatingSavings": 181
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "178.15550614059",
   "coolingKwh": "1.8474762519356667",
   "overheatingSavings": 245
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "181.40451764104225",
   "coolingKwh": "0.9981859773273445",
   "overheatingSavings": 151
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "168.77721854694113",
   "coolingKwh": "7.083275368713145",
   "overheatingSavings": 630
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "187.46794270741887",
   "coolingKwh": "5.036352977416856",
   "overheatingSavings": 480
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "191.66538839623223",
   "coolingKwh": "2.875360618508944",
   "overheatingSavings": 330
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "175.7181915612233",
   "coolingKwh": "16.085727973729444",
   "overheatingSavings": 1012
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "119.23733608313333",
   "coolingKwh": "0.25043858151383",
   "overheatingSavings": 69
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "121.07225605588444",
   "coolingKwh": "0.11642826626437557",
   "overheatingSavings": 33
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "113.65358985933221",
   "coolingKwh": "1.431892335358489",
   "overheatingSavings": 260
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "127.95412073146444",
   "coolingKwh": "2.1588730202067885",
   "overheatingSavings": 338
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "130.99655212071556",
   "coolingKwh": "1.0728537396128601",
   "overheatingSavings": 186
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "119.28004696640335",
   "coolingKwh": "8.376884524733278",
   "overheatingSavings": 862
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "137.52061796722109",
   "coolingKwh": "5.8235339657793785",
   "overheatingSavings": 622
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "141.43744319722555",
   "coolingKwh": "3.251532174181989",
   "overheatingSavings": 418
 },
 {
   "location": "Prague",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "126.77446314851665",
   "coolingKwh": "18.401300176726114",
   "overheatingSavings": 1332
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "172.51967944086223",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "174.0661592073789",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "167.60825326602225",
   "coolingKwh": "0.047408412183093114",
   "overheatingSavings": 10
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "180.11216540319558",
   "coolingKwh": "0.09972617180410122",
   "overheatingSavings": 13
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "182.84341068832111",
   "coolingKwh": "0.034085513271946664",
   "overheatingSavings": 7
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "171.75112626170443",
   "coolingKwh": "1.2067061702363888",
   "overheatingSavings": 126
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "188.1073361305622",
   "coolingKwh": "0.6668139476105656",
   "overheatingSavings": 80
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "191.8117864015178",
   "coolingKwh": "0.2355950849407967",
   "overheatingSavings": 36
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "177.1294581096022",
   "coolingKwh": "5.097051761244945",
   "overheatingSavings": 342
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "95.37430561073845",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "96.78091636832322",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "91.04751913052989",
   "coolingKwh": "0.08257961758931434",
   "overheatingSavings": 21
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "103.35307888200167",
   "coolingKwh": "0.19572436115126776",
   "overheatingSavings": 42
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "105.78334044655489",
   "coolingKwh": "0.040180196051605666",
   "overheatingSavings": 11
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "96.15816612082455",
   "coolingKwh": "2.538417228047511",
   "overheatingSavings": 301
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "111.63182626380222",
   "coolingKwh": "1.2743209555971111",
   "overheatingSavings": 158
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "114.93051932513667",
   "coolingKwh": "0.41756445182593444",
   "overheatingSavings": 69
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "102.23564184184755",
   "coolingKwh": "8.680041850682588",
   "overheatingSavings": 667
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "57.788323627282786",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "58.993159842047106",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "54.085033647955676",
   "coolingKwh": "0.35948767200271114",
   "overheatingSavings": 98
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "66.10612238106022",
   "coolingKwh": "0.5265737188769289",
   "overheatingSavings": 110
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "68.22729729013645",
   "coolingKwh": "0.12381012169821999",
   "overheatingSavings": 38
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "60.09046250556577",
   "coolingKwh": "4.791974296019888",
   "overheatingSavings": 636
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "74.59954490768133",
   "coolingKwh": "2.385694427990722",
   "overheatingSavings": 343
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "77.48651919165655",
   "coolingKwh": "0.872486492300511",
   "overheatingSavings": 144
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "66.74027625881057",
   "coolingKwh": "12.766012533340112",
   "overheatingSavings": 1166
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "231.94361081807446",
   "coolingKwh": "0.3590669237629089",
   "overheatingSavings": 65
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "234.05290224248552",
   "coolingKwh": "0.23935746177492223",
   "overheatingSavings": 48
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "225.27684610963445",
   "coolingKwh": "1.1086100359819013",
   "overheatingSavings": 149
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "241.29062152509113",
   "coolingKwh": "1.6458860901950891",
   "overheatingSavings": 198
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "244.9534003755811",
   "coolingKwh": "0.9903854138910334",
   "overheatingSavings": 133
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "230.46954811563333",
   "coolingKwh": "6.081509465441478",
   "overheatingSavings": 526
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "251.4887917630211",
   "coolingKwh": "4.442126407039688",
   "overheatingSavings": 403
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "256.2741517209822",
   "coolingKwh": "2.529061572229033",
   "overheatingSavings": 273
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "237.90925916174558",
   "coolingKwh": "14.472210078867",
   "overheatingSavings": 856
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "130.81811908842",
   "coolingKwh": "0.27476580124736444",
   "overheatingSavings": 74
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "132.80348265087002",
   "coolingKwh": "0.1529982710566989",
   "overheatingSavings": 49
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "124.76375754732001",
   "coolingKwh": "1.3160034885340999",
   "overheatingSavings": 255
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "140.6091500083722",
   "coolingKwh": "1.953796765334644",
   "overheatingSavings": 328
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "143.93828811867667",
   "coolingKwh": "1.0297012461283965",
   "overheatingSavings": 193
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "131.15478900226555",
   "coolingKwh": "8.097727241140921",
   "overheatingSavings": 870
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "151.32488174393444",
   "coolingKwh": "5.6200282921834335",
   "overheatingSavings": 633
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "155.59198433265223",
   "coolingKwh": "3.0872551201649223",
   "overheatingSavings": 432
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "139.56414675953556",
   "coolingKwh": "18.34467784474778",
   "overheatingSavings": 1365
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "81.16554722751121",
   "coolingKwh": "0.4384205689081722",
   "overheatingSavings": 154
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "82.91737986047232",
   "coolingKwh": "0.2018452019346011",
   "overheatingSavings": 75
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "75.97573253353912",
   "coolingKwh": "2.2365105971957777",
   "overheatingSavings": 549
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "91.51942748766778",
   "coolingKwh": "2.8662200113883447",
   "overheatingSavings": 577
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "94.43638026556044",
   "coolingKwh": "1.4304489529630333",
   "overheatingSavings": 332
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "83.28671478562967",
   "coolingKwh": "10.8113718528397",
   "overheatingSavings": 1483
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "102.43466456153844",
   "coolingKwh": "7.177078170491089",
   "overheatingSavings": 979
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "106.27183644003179",
   "coolingKwh": "4.025199634244278",
   "overheatingSavings": 652
 },
 {
   "location": "Prague",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "92.24023909763544",
   "coolingKwh": "22.332040911713108",
   "overheatingSavings": 2040
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "197.3456083501989",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "199.15799797551554",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "191.5213022113367",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "201.78447660916444",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "205.0825835597178",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "191.64762677358334",
   "coolingKwh": "0.0050467430412714",
   "overheatingSavings": 2
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "206.60614929540776",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "211.15582067539003",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "192.97374397638444",
   "coolingKwh": "0.6304853956779355",
   "overheatingSavings": 74
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "121.05288221526335",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "122.6535032555711",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "116.00176324631667",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "126.23168080974109",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "129.09891648717442",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "117.45949214877223",
   "coolingKwh": "0.14088668914280553",
   "overheatingSavings": 30
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "131.67985244494443",
   "coolingKwh": "0.002061772214133811",
   "overheatingSavings": 1
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "135.6446463536522",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "120.06358745124224",
   "coolingKwh": "1.7812080783870998",
   "overheatingSavings": 207
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "83.6716967970679",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "85.13602596969221",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "79.04752512087022",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "89.23495610580034",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "91.86761356661356",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "81.720638971309",
   "coolingKwh": "0.5913583248408456",
   "overheatingSavings": 101
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "95.086112907029",
   "coolingKwh": "0.10348998115067443",
   "overheatingSavings": 30
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "98.65595984621277",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "85.42970199872234",
   "coolingKwh": "3.775101944568088",
   "overheatingSavings": 508
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "268.12196376060444",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "270.6143704138722",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "260.0359218159511",
   "coolingKwh": "0.06869375991109655",
   "overheatingSavings": 18
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "273.0781984883244",
   "coolingKwh": "0.15119396146357",
   "overheatingSavings": 33
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "277.65179826660005",
   "coolingKwh": "0.04382617525843389",
   "overheatingSavings": 13
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "258.92836377074997",
   "coolingKwh": "1.2338923842365332",
   "overheatingSavings": 155
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "278.6016594438567",
   "coolingKwh": "0.7084912863005233",
   "overheatingSavings": 96
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "284.9210861422234",
   "coolingKwh": "0.29216947538824445",
   "overheatingSavings": 47
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "259.69686740750666",
   "coolingKwh": "4.371289392300556",
   "overheatingSavings": 402
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "167.4947420981789",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "169.8338798369467",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "160.1288566140078",
   "coolingKwh": "0.09385466399263934",
   "overheatingSavings": 33
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "173.22127282846776",
   "coolingKwh": "0.2258009271154089",
   "overheatingSavings": 56
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "177.35832720923332",
   "coolingKwh": "0.03377574967138756",
   "overheatingSavings": 11
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "160.89911661716445",
   "coolingKwh": "2.146275542131211",
   "overheatingSavings": 329
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "179.57853502489112",
   "coolingKwh": "1.1445324885136887",
   "overheatingSavings": 177
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "185.1926472348744",
   "coolingKwh": "0.46800480398252786",
   "overheatingSavings": 90
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "163.6018852561622",
   "coolingKwh": "7.349085317291967",
   "overheatingSavings": 838
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "117.77590084174446",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "119.93845815972223",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "111.1652884032089",
   "coolingKwh": "0.2780950111566889",
   "overheatingSavings": 83
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "124.21875608919335",
   "coolingKwh": "0.4683668351349933",
   "overheatingSavings": 112
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "127.91761937739",
   "coolingKwh": "0.09295476726675",
   "overheatingSavings": 35
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "113.58418867329667",
   "coolingKwh": "3.7670312493413447",
   "overheatingSavings": 691
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "131.33950785162222",
   "coolingKwh": "1.8443234641108666",
   "overheatingSavings": 342
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "136.23557033463555",
   "coolingKwh": "0.7573097875271789",
   "overheatingSavings": 153
 },
 {
   "location": "Prague",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "117.41818689758112",
   "coolingKwh": "10.761190156161522",
   "overheatingSavings": 1469
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "170.60535216947332",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "172.4515890428711",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "164.75078211961778",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "176.19993552394223",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "179.50310093193778",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "166.1228126516478",
   "coolingKwh": "0.04697175663831389",
   "overheatingSavings": 11
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "182.18033878613224",
   "coolingKwh": "0.00015056075479742112",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "186.7110658947322",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "168.62283432079667",
   "coolingKwh": "1.1885936364065444",
   "overheatingSavings": 116
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "95.10208568767256",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "96.68659475086801",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "90.105387076077",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "101.49901828239399",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "104.340660987383",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "93.18313199738456",
   "coolingKwh": "0.7023679300942911",
   "overheatingSavings": 116
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "108.13739565821824",
   "coolingKwh": "0.13667559161238113",
   "overheatingSavings": 33
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "112.03513076886223",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "97.50467284055856",
   "coolingKwh": "4.013576320662355",
   "overheatingSavings": 518
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "58.24848654315489",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "59.62654675943055",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "54.29233385782777",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "65.37780839057746",
   "coolingKwh": "0.007912706461384533",
   "overheatingSavings": 8
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "67.70568871538946",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "59.01095381162833",
   "coolingKwh": "2.355053365191411",
   "overheatingSavings": 530
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "72.73232819995344",
   "coolingKwh": "0.73636469867416",
   "overheatingSavings": 152
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "75.81031898049156",
   "coolingKwh": "0.09603540348841745",
   "overheatingSavings": 29
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "64.20946042869988",
   "coolingKwh": "8.586275864991679",
   "overheatingSavings": 1364
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "230.81730961307002",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "233.39806232204666",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "222.59105356561778",
   "coolingKwh": "0.12160612331844223",
   "overheatingSavings": 33
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "237.22990712548778",
   "coolingKwh": "0.24332251337904554",
   "overheatingSavings": 45
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "241.8558709248444",
   "coolingKwh": "0.07640481624989667",
   "overheatingSavings": 22
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "223.10193312555444",
   "coolingKwh": "1.8032535306494777",
   "overheatingSavings": 226
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "244.2793413221711",
   "coolingKwh": "1.0825813510894533",
   "overheatingSavings": 150
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "250.59477862870446",
   "coolingKwh": "0.4575951408029745",
   "overheatingSavings": 72
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "225.58356102999778",
   "coolingKwh": "6.091945901977344",
   "overheatingSavings": 588
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "130.88282221254335",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "133.18099582652556",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "123.80815758287333",
   "coolingKwh": "0.3896382809411089",
   "overheatingSavings": 102
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "138.43290937607776",
   "coolingKwh": "0.60680603562601",
   "overheatingSavings": 136
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "142.40189228485667",
   "coolingKwh": "0.17705323711305998",
   "overheatingSavings": 54
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "127.02348735247445",
   "coolingKwh": "4.172291158331333",
   "overheatingSavings": 739
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "146.6547245604",
   "coolingKwh": "2.127549895509033",
   "overheatingSavings": 370
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "151.90766360240113",
   "coolingKwh": "0.9392803738764598",
   "overheatingSavings": 178
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "131.70984491726776",
   "coolingKwh": "11.473798023675556",
   "overheatingSavings": 1481
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "82.12829568866911",
   "coolingKwh": "0.033977525218512335",
   "overheatingSavings": 21
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "84.05309005004945",
   "coolingKwh": "0",
   "overheatingSavings": 0
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "76.35149141114678",
   "coolingKwh": "1.0604574976012557",
   "overheatingSavings": 367
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "90.80705670319844",
   "coolingKwh": "1.3031745968255777",
   "overheatingSavings": 379
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "94.101763985157",
   "coolingKwh": "0.5375385223575878",
   "overheatingSavings": 162
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "81.24394095091567",
   "coolingKwh": "7.891376648708456",
   "overheatingSavings": 1708
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "99.73991334128468",
   "coolingKwh": "4.224167356795633",
   "overheatingSavings": 939
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "104.24136723330479",
   "coolingKwh": "1.7984680422039334",
   "overheatingSavings": 438
 },
 {
   "location": "Prague",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "87.52435610975122",
   "coolingKwh": "17.542130933199335",
   "overheatingSavings": 2294
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0.026947643464292112",
   "coolingKwh": "114.32936717812",
   "overheatingSavings": 4683
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0.030534500726693",
   "coolingKwh": "110.69452384896323",
   "overheatingSavings": 4639
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0.017742347128195446",
   "coolingKwh": "126.86773592812887",
   "overheatingSavings": 4822
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0.04844453105515778",
   "coolingKwh": "133.01704766963223",
   "overheatingSavings": 4827
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0.06260427781078655",
   "coolingKwh": "125.59890269871666",
   "overheatingSavings": 4742
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0.022120065743262442",
   "coolingKwh": "158.93293555502444",
   "overheatingSavings": 5073
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0.083559288844199",
   "coolingKwh": "151.75654055944557",
   "overheatingSavings": 4947
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "0.10859969418392268",
   "coolingKwh": "140.49637550798556",
   "overheatingSavings": 4843
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0.029264452917261333",
   "coolingKwh": "191.57746852413112",
   "overheatingSavings": 5322
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "89.80117793568355",
   "overheatingSavings": 4926
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0",
   "coolingKwh": "86.06722532059645",
   "overheatingSavings": 4880
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "102.82602054753333",
   "overheatingSavings": 5144
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "108.81737021703734",
   "overheatingSavings": 5125
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0",
   "coolingKwh": "101.133255914587",
   "overheatingSavings": 5017
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "135.93993122032444",
   "overheatingSavings": 5485
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "127.88899277445002",
   "overheatingSavings": 5280
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "0",
   "coolingKwh": "116.20075685087",
   "overheatingSavings": 5134
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "170.1978314657167",
   "overheatingSavings": 5816
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "78.19412481232867",
   "overheatingSavings": 5165
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0",
   "coolingKwh": "74.33925471207344",
   "overheatingSavings": 5088
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "91.67332170377122",
   "overheatingSavings": 5396
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "97.51588039901911",
   "overheatingSavings": 5368
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0",
   "coolingKwh": "89.590979192484",
   "overheatingSavings": 5246
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "125.96446095322221",
   "overheatingSavings": 5887
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "116.99096938785667",
   "overheatingSavings": 5560
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "0",
   "coolingKwh": "104.812770085032",
   "overheatingSavings": 5368
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "161.50772879971777",
   "overheatingSavings": 6278
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "2.6257153963376774",
   "coolingKwh": "186.0510718857089",
   "overheatingSavings": 5687
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "2.7584279104102554",
   "coolingKwh": "181.8150247883489",
   "overheatingSavings": 5631
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "2.2169573609597113",
   "coolingKwh": "200.70620198235557",
   "overheatingSavings": 5846
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "2.951005316932089",
   "coolingKwh": "208.35392827279554",
   "overheatingSavings": 5835
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "3.217611098204367",
   "coolingKwh": "199.73890483985113",
   "overheatingSavings": 5742
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "2.2082805257355553",
   "coolingKwh": "238.4974601476989",
   "overheatingSavings": 6137
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "3.3146465542519445",
   "coolingKwh": "230.5977452934211",
   "overheatingSavings": 5960
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "3.7139231172704776",
   "coolingKwh": "217.5281369013722",
   "overheatingSavings": 5834
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "2.277553288917878",
   "coolingKwh": "276.82460450264443",
   "overheatingSavings": 6400
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0.046415684627033556",
   "coolingKwh": "149.83194870146443",
   "overheatingSavings": 6207
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0.058724486800569",
   "coolingKwh": "145.35630495458443",
   "overheatingSavings": 6127
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0.022814668079339667",
   "coolingKwh": "165.3682057189989",
   "overheatingSavings": 6453
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0.10447752430111933",
   "coolingKwh": "172.6762941629133",
   "overheatingSavings": 6376
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0.13846443632696556",
   "coolingKwh": "163.5671049330178",
   "overheatingSavings": 6238
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0.035811415506451554",
   "coolingKwh": "204.83913645459222",
   "overheatingSavings": 6842
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0.18714468605775775",
   "coolingKwh": "195.49720919763664",
   "overheatingSavings": 6524
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "0.26118311764837776",
   "coolingKwh": "181.65356705234555",
   "overheatingSavings": 6337
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0.05261751783455322",
   "coolingKwh": "244.71545388146885",
   "overheatingSavings": 7121
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "133.39040036810113",
   "overheatingSavings": 6755
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0",
   "coolingKwh": "128.65773750603222",
   "overheatingSavings": 6641
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "149.9066897695022",
   "overheatingSavings": 7125
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "156.72421565906888",
   "overheatingSavings": 6950
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0",
   "coolingKwh": "147.10020293265555",
   "overheatingSavings": 6768
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "190.6932371090889",
   "overheatingSavings": 7499
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "179.93739152010113",
   "overheatingSavings": 7073
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "0",
   "coolingKwh": "165.39947219605114",
   "overheatingSavings": 6851
 },
 {
   "location": "Riyadh",
   "variant": "E1",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "231.41457322947667",
   "overheatingSavings": 7728
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "3.899057059049866",
   "coolingKwh": "116.58929549918221",
   "overheatingSavings": 4782
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "4.04746519537081",
   "coolingKwh": "111.70379708749333",
   "overheatingSavings": 4737
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "3.438002072256567",
   "coolingKwh": "133.53558310382002",
   "overheatingSavings": 4967
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "4.249463984871834",
   "coolingKwh": "142.3605519790511",
   "overheatingSavings": 4967
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "4.5217520622076215",
   "coolingKwh": "132.29506605181777",
   "overheatingSavings": 4865
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "3.464706259810889",
   "coolingKwh": "178.5356616268389",
   "overheatingSavings": 5357
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "4.694735279691389",
   "coolingKwh": "168.73333358124333",
   "overheatingSavings": 5144
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "5.078027802309067",
   "coolingKwh": "153.08095610136667",
   "overheatingSavings": 4984
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "3.7071606306943337",
   "coolingKwh": "226.11567328463113",
   "overheatingSavings": 5701
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0.5851384926822345",
   "coolingKwh": "91.39010762447867",
   "overheatingSavings": 5149
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0.64826981448917",
   "coolingKwh": "86.33336914502756",
   "overheatingSavings": 5060
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0.40731494535599005",
   "coolingKwh": "109.24602630917711",
   "overheatingSavings": 5476
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0.7298304246867267",
   "coolingKwh": "117.88991254579888",
   "overheatingSavings": 5420
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0.8560445275405644",
   "coolingKwh": "107.25731971729044",
   "overheatingSavings": 5245
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0.4067928417752389",
   "coolingKwh": "156.62809591297778",
   "overheatingSavings": 6000
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0.9178873770308011",
   "coolingKwh": "145.24569374064998",
   "overheatingSavings": 5679
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "1.1110769362702122",
   "coolingKwh": "128.56857914094556",
   "overheatingSavings": 5436
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0.46155753067989225",
   "coolingKwh": "206.18257203777554",
   "overheatingSavings": 6394
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0.02175676647839611",
   "coolingKwh": "79.91332586426311",
   "overheatingSavings": 5535
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0.03131404942375045",
   "coolingKwh": "74.59121302949389",
   "overheatingSavings": 5400
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0.002119406710793056",
   "coolingKwh": "99.06502175150321",
   "overheatingSavings": 5985
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0.044630906384737226",
   "coolingKwh": "107.37293760780778",
   "overheatingSavings": 5886
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0.06307026826884622",
   "coolingKwh": "96.05019364215732",
   "overheatingSavings": 5655
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0.0023833341503719666",
   "coolingKwh": "148.4654202457",
   "overheatingSavings": 6614
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0.07986653138285545",
   "coolingKwh": "135.38737759679444",
   "overheatingSavings": 6130
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "0.1376175861593589",
   "coolingKwh": "117.88808281281555",
   "overheatingSavings": 5849
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0.007460852425392122",
   "coolingKwh": "199.07376855530447",
   "overheatingSavings": 6996
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "14.13929155569",
   "coolingKwh": "165.83767144539888",
   "overheatingSavings": 5615
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "14.510529613696",
   "coolingKwh": "160.27746989952223",
   "overheatingSavings": 5543
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "13.096224583963332",
   "coolingKwh": "185.40420919510666",
   "overheatingSavings": 5882
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "15.118708651968554",
   "coolingKwh": "196.80178823474333",
   "overheatingSavings": 5905
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "15.65574825052511",
   "coolingKwh": "185.08940909714667",
   "overheatingSavings": 5759
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "13.848644781372778",
   "coolingKwh": "238.51917001742888",
   "overheatingSavings": 6333
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "16.505869931867668",
   "coolingKwh": "228.59291236252886",
   "overheatingSavings": 6143
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "17.09933774526989",
   "coolingKwh": "210.41861162219777",
   "overheatingSavings": 5944
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "15.242181310076",
   "coolingKwh": "292.85948538362777",
   "overheatingSavings": 6639
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "4.1776126670318",
   "coolingKwh": "127.85062446169779",
   "overheatingSavings": 6037
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "4.4568633150556884",
   "coolingKwh": "122.05208097573",
   "overheatingSavings": 5939
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "3.4281628156680664",
   "coolingKwh": "148.31564051343778",
   "overheatingSavings": 6427
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "4.874448873032189",
   "coolingKwh": "159.34831014756668",
   "overheatingSavings": 6393
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "5.256145617384933",
   "coolingKwh": "147.11808624214999",
   "overheatingSavings": 6195
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "4.1277812748020555",
   "coolingKwh": "202.73894808490553",
   "overheatingSavings": 6968
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "6.0552290789673",
   "coolingKwh": "191.61629120330667",
   "overheatingSavings": 6650
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "6.434201295163133",
   "coolingKwh": "172.7725812860722",
   "overheatingSavings": 6415
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "5.388673240001934",
   "coolingKwh": "257.8255672575034",
   "overheatingSavings": 7257
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0.9671429387168823",
   "coolingKwh": "110.5212443627461",
   "overheatingSavings": 6580
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "1.1027544647094023",
   "coolingKwh": "104.41366450625434",
   "overheatingSavings": 6444
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0.6149799027380778",
   "coolingKwh": "132.02661355781444",
   "overheatingSavings": 7048
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "1.2922853703704447",
   "coolingKwh": "142.41386406850444",
   "overheatingSavings": 6916
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "1.5225704861323779",
   "coolingKwh": "129.74494034448668",
   "overheatingSavings": 6659
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0.9190689031844776",
   "coolingKwh": "187.02150570030776",
   "overheatingSavings": 7546
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "2.0328778552711775",
   "coolingKwh": "174.7225850512411",
   "overheatingSavings": 7131
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "2.2668168638802",
   "coolingKwh": "155.3951923007189",
   "overheatingSavings": 6850
 },
 {
   "location": "Riyadh",
   "variant": "E2",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "1.7046822420826557",
   "coolingKwh": "242.02005310945887",
   "overheatingSavings": 7776
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "3.644122486176967",
   "coolingKwh": "103.97755474303966",
   "overheatingSavings": 4808
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "3.8466352936431",
   "coolingKwh": "98.90628671034933",
   "overheatingSavings": 4740
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "3.0418967686544334",
   "coolingKwh": "121.62636992980553",
   "overheatingSavings": 5005
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "3.8602272076640998",
   "coolingKwh": "131.46725506969554",
   "overheatingSavings": 5018
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "4.210123614337245",
   "coolingKwh": "120.9910845375489",
   "overheatingSavings": 4906
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "2.8847613564308334",
   "coolingKwh": "169.08180789268332",
   "overheatingSavings": 5452
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "4.19785219522509",
   "coolingKwh": "159.51173395341223",
   "overheatingSavings": 5211
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "4.673957002244289",
   "coolingKwh": "143.26403805127222",
   "overheatingSavings": 5036
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "2.9698925671300445",
   "coolingKwh": "219.4608496423922",
   "overheatingSavings": 5839
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0.45570139519059777",
   "coolingKwh": "79.34970241036234",
   "overheatingSavings": 5239
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0.5354203363254889",
   "coolingKwh": "74.03709853937855",
   "overheatingSavings": 5159
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0.23488088615497107",
   "coolingKwh": "98.02054099090522",
   "overheatingSavings": 5577
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0.5341001678550289",
   "coolingKwh": "107.55561145301132",
   "overheatingSavings": 5533
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0.684392603889681",
   "coolingKwh": "96.47475343122355",
   "overheatingSavings": 5363
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0.1834802780031",
   "coolingKwh": "148.43160785154",
   "overheatingSavings": 6223
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0.6682073144231888",
   "coolingKwh": "136.76413282851112",
   "overheatingSavings": 5815
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "0.8853011861941233",
   "coolingKwh": "119.24958620863333",
   "overheatingSavings": 5552
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0.1898741805210233",
   "coolingKwh": "201.25456844274555",
   "overheatingSavings": 6683
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "68.43233891620744",
   "overheatingSavings": 5735
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0.0033918518513186002",
   "coolingKwh": "62.793601163774554",
   "overheatingSavings": 5559
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "88.94224558146556",
   "overheatingSavings": 6381
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0.005046085834934478",
   "coolingKwh": "97.87061737324055",
   "overheatingSavings": 6178
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0.027691300682173892",
   "coolingKwh": "85.82225611640723",
   "overheatingSavings": 5864
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "141.95933576595",
   "overheatingSavings": 7101
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0.02715543255524367",
   "coolingKwh": "127.88020850783666",
   "overheatingSavings": 6436
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "0.06192096745643688",
   "coolingKwh": "109.26324153386666",
   "overheatingSavings": 6071
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "195.93057342598777",
   "overheatingSavings": 7483
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "12.676749413914443",
   "coolingKwh": "146.10891534314888",
   "overheatingSavings": 5527
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "13.189812656376445",
   "coolingKwh": "140.47616410679444",
   "overheatingSavings": 5442
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "11.191028761936332",
   "coolingKwh": "165.97934516361224",
   "overheatingSavings": 5823
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "13.261259679544333",
   "coolingKwh": "178.65735457969998",
   "overheatingSavings": 5857
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "14.005410097371001",
   "coolingKwh": "166.66604254924223",
   "overheatingSavings": 5687
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "11.551186482273334",
   "coolingKwh": "221.92942824511775",
   "overheatingSavings": 6377
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "14.508577860855556",
   "coolingKwh": "212.49922989451554",
   "overheatingSavings": 6146
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "15.275440512196447",
   "coolingKwh": "193.62932092572333",
   "overheatingSavings": 5923
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "12.952659531195332",
   "coolingKwh": "279.92348837327336",
   "overheatingSavings": 6750
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "3.115539602212122",
   "coolingKwh": "108.52219255857845",
   "overheatingSavings": 6069
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "3.4585913025809227",
   "coolingKwh": "102.55401159765155",
   "overheatingSavings": 5922
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "2.1725710320196447",
   "coolingKwh": "129.64366898824446",
   "overheatingSavings": 6508
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "3.3920071312632447",
   "coolingKwh": "141.72796891830333",
   "overheatingSavings": 6440
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "3.9434954622568004",
   "coolingKwh": "129.17288063084888",
   "overheatingSavings": 6201
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "2.3611698238031664",
   "coolingKwh": "187.06300595722777",
   "overheatingSavings": 7161
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "4.277820571262566",
   "coolingKwh": "176.0241067959511",
   "overheatingSavings": 6753
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "4.808605881374",
   "coolingKwh": "156.36977151217332",
   "overheatingSavings": 6454
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "3.4987054783226776",
   "coolingKwh": "245.8153750572389",
   "overheatingSavings": 7514
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0.42675436067928557",
   "coolingKwh": "91.707753753816",
   "overheatingSavings": 6802
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0.5469322607413222",
   "coolingKwh": "85.29206807261343",
   "overheatingSavings": 6599
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0.14600475459275666",
   "coolingKwh": "114.49537346967445",
   "overheatingSavings": 7429
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0.5994231940952445",
   "coolingKwh": "125.78125345344112",
   "overheatingSavings": 7156
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0.8326590582930632",
   "coolingKwh": "112.4666383707389",
   "overheatingSavings": 6839
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0.1972328391745589",
   "coolingKwh": "172.97199749490557",
   "overheatingSavings": 7940
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0.9399746138083378",
   "coolingKwh": "160.10579780382",
   "overheatingSavings": 7373
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "1.2419925073269444",
   "coolingKwh": "139.78546798689",
   "overheatingSavings": 7012
 },
 {
   "location": "Riyadh",
   "variant": "E3",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0.6327521650788533",
   "coolingKwh": "231.34897150987337",
   "overheatingSavings": 8139
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "1.5712516010442446",
   "coolingKwh": "117.2250279170889",
   "overheatingSavings": 4845
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "1.7355864247732111",
   "coolingKwh": "112.17220659819444",
   "overheatingSavings": 4778
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "1.1102878979199124",
   "coolingKwh": "134.78660669479444",
   "overheatingSavings": 5062
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "1.3635805102476555",
   "coolingKwh": "143.65009077833443",
   "overheatingSavings": 5076
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "1.6373502719399444",
   "coolingKwh": "133.26223582778888",
   "overheatingSavings": 4954
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0.6912911661368533",
   "coolingKwh": "180.18437492338336",
   "overheatingSavings": 5410
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "1.2193444540885445",
   "coolingKwh": "170.2705970755",
   "overheatingSavings": 5246
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "1.5763217223727333",
   "coolingKwh": "154.4151444923811",
   "overheatingSavings": 5104
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0.43997187702323554",
   "coolingKwh": "227.10225424152446",
   "overheatingSavings": 5754
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0.06654893658911755",
   "coolingKwh": "93.49531227653978",
   "overheatingSavings": 5180
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0.10228067909181933",
   "coolingKwh": "88.17338931157822",
   "overheatingSavings": 5102
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0.003712116179860044",
   "coolingKwh": "112.06482891027555",
   "overheatingSavings": 5458
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0.03814793481698134",
   "coolingKwh": "120.82872267875888",
   "overheatingSavings": 5440
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0.09287416851881533",
   "coolingKwh": "109.85585636750712",
   "overheatingSavings": 5287
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "160.18925652322332",
   "overheatingSavings": 6059
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0.02923125327990233",
   "coolingKwh": "148.48363468627778",
   "overheatingSavings": 5692
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "0.08991786037035611",
   "coolingKwh": "131.62455493124997",
   "overheatingSavings": 5465
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "210.13394511631444",
   "overheatingSavings": 6525
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "82.70295005554145",
   "overheatingSavings": 5530
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0",
   "coolingKwh": "77.12308160094823",
   "overheatingSavings": 5403
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "102.47096891607045",
   "overheatingSavings": 6017
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "110.99181979139978",
   "overheatingSavings": 5921
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0",
   "coolingKwh": "99.37370868712689",
   "overheatingSavings": 5680
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "153.21876333001444",
   "overheatingSavings": 6757
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "139.70072012358776",
   "overheatingSavings": 6231
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "0",
   "coolingKwh": "121.72099160507335",
   "overheatingSavings": 5890
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "205.5337448882811",
   "overheatingSavings": 7277
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "8.93947459375661",
   "coolingKwh": "167.76631075028553",
   "overheatingSavings": 5543
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "9.558438703758044",
   "coolingKwh": "162.11055631296",
   "overheatingSavings": 5479
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "7.102650531922968",
   "coolingKwh": "187.38797465652445",
   "overheatingSavings": 5790
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "7.999620613786665",
   "coolingKwh": "198.58958951184445",
   "overheatingSavings": 5796
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "9.049876370214822",
   "coolingKwh": "186.9741639036711",
   "overheatingSavings": 5662
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "5.213128047082966",
   "coolingKwh": "239.61347238741223",
   "overheatingSavings": 6243
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "7.344955967997788",
   "coolingKwh": "229.70155933636445",
   "overheatingSavings": 6025
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "8.706057961332055",
   "coolingKwh": "211.89354434091666",
   "overheatingSavings": 5821
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "4.070188705209711",
   "coolingKwh": "293.2063445136678",
   "overheatingSavings": 6637
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "1.8531028077112446",
   "coolingKwh": "131.06046088335",
   "overheatingSavings": 6065
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "2.1495427297794447",
   "coolingKwh": "125.05131656179665",
   "overheatingSavings": 5944
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "1.1104057262217177",
   "coolingKwh": "152.08009391090002",
   "overheatingSavings": 6430
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "1.579916305733589",
   "coolingKwh": "163.09950775029",
   "overheatingSavings": 6376
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "2.037596071292589",
   "coolingKwh": "150.7033973928911",
   "overheatingSavings": 6189
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0.5916373207950444",
   "coolingKwh": "207.16647636991556",
   "overheatingSavings": 7048
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "1.4267917795198668",
   "coolingKwh": "195.34302560192776",
   "overheatingSavings": 6650
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "2.002089925551211",
   "coolingKwh": "176.38875435809112",
   "overheatingSavings": 6378
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0.3360322620359178",
   "coolingKwh": "263.3350499260289",
   "overheatingSavings": 7482
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0.20784649065124222",
   "coolingKwh": "114.31838055432667",
   "overheatingSavings": 6634
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0.28358792696227886",
   "coolingKwh": "107.92621469713878",
   "overheatingSavings": 6475
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0.03568479365521511",
   "coolingKwh": "136.75351271999332",
   "overheatingSavings": 7141
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0.16205698256358667",
   "coolingKwh": "147.37679560332109",
   "overheatingSavings": 6989
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0.2977792271720811",
   "coolingKwh": "134.26100872829",
   "overheatingSavings": 6735
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "194.10220026193332",
   "overheatingSavings": 7769
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0.13860286380184667",
   "coolingKwh": "180.50896679736556",
   "overheatingSavings": 7279
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "0.3280161115530733",
   "coolingKwh": "160.48440846177",
   "overheatingSavings": 6917
 },
 {
   "location": "Riyadh",
   "variant": "E4",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0.00057227665861934",
   "coolingKwh": "251.7103483206567",
   "overheatingSavings": 8146
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "1.7233734432860668",
   "coolingKwh": "106.37535225839521",
   "overheatingSavings": 4863
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "1.9348863530253446",
   "coolingKwh": "101.10166229952844",
   "overheatingSavings": 4789
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "1.1370533079589336",
   "coolingKwh": "124.80570920592665",
   "overheatingSavings": 5085
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "1.4061428549828445",
   "coolingKwh": "134.72649235241002",
   "overheatingSavings": 5100
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "1.7458234025039001",
   "coolingKwh": "123.805799921392",
   "overheatingSavings": 4991
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0.6207019077489644",
   "coolingKwh": "173.3555493099689",
   "overheatingSavings": 5484
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "1.1943642994381334",
   "coolingKwh": "163.3733415910667",
   "overheatingSavings": 5293
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "1.622454282668889",
   "coolingKwh": "146.6379753119711",
   "overheatingSavings": 5137
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0.33849548883128",
   "coolingKwh": "223.66804782497002",
   "overheatingSavings": 5887
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0.07355525438261133",
   "coolingKwh": "82.95124984246846",
   "overheatingSavings": 5208
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0.12303371685936777",
   "coolingKwh": "77.3342133397541",
   "overheatingSavings": 5113
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "102.70366058891956",
   "overheatingSavings": 5600
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0.025644393282362888",
   "coolingKwh": "112.49070611091112",
   "overheatingSavings": 5570
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0.09571605584688378",
   "coolingKwh": "100.82645547180134",
   "overheatingSavings": 5352
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "154.75062665126666",
   "overheatingSavings": 6308
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0.011607032936201333",
   "coolingKwh": "142.43392427384447",
   "overheatingSavings": 5859
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "0.080737848651828",
   "coolingKwh": "124.43245058264667",
   "overheatingSavings": 5575
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "208.70273721143445",
   "overheatingSavings": 6859
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "72.67812469329922",
   "overheatingSavings": 5759
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0",
   "coolingKwh": "66.67566009995922",
   "overheatingSavings": 5581
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "94.2523276404101",
   "overheatingSavings": 6443
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "103.55399086540622",
   "overheatingSavings": 6242
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0",
   "coolingKwh": "90.94903729414101",
   "overheatingSavings": 5927
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "149.69240124019333",
   "overheatingSavings": 7267
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0",
   "coolingKwh": "134.74341813178665",
   "overheatingSavings": 6563
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "0",
   "coolingKwh": "115.31895128398557",
   "overheatingSavings": 6150
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T1",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "206.20177361752778",
   "overheatingSavings": 7745
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "8.823481295107177",
   "coolingKwh": "150.06699988334333",
   "overheatingSavings": 5505
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "9.557701951815424",
   "coolingKwh": "144.17108057053554",
   "overheatingSavings": 5442
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "6.684857574477734",
   "coolingKwh": "170.54146118888778",
   "overheatingSavings": 5801
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "7.599327284043856",
   "coolingKwh": "183.12418541419444",
   "overheatingSavings": 5806
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "8.805544452695234",
   "coolingKwh": "170.99099331955443",
   "overheatingSavings": 5642
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "4.537504306141222",
   "coolingKwh": "226.2302652158033",
   "overheatingSavings": 6310
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "6.781267176638366",
   "coolingKwh": "216.54191381733776",
   "overheatingSavings": 6056
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "8.295953816446",
   "coolingKwh": "197.87776353312668",
   "overheatingSavings": 5838
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A1",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "3.318254175325767",
   "coolingKwh": "283.48961564680445",
   "overheatingSavings": 6748
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "1.5906800341539553",
   "coolingKwh": "113.46729166384888",
   "overheatingSavings": 6112
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "1.9369153600687667",
   "coolingKwh": "107.16088617466146",
   "overheatingSavings": 5957
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0.7925377109293955",
   "coolingKwh": "135.6762556219778",
   "overheatingSavings": 6567
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "1.2564811704671",
   "coolingKwh": "148.0287572600022",
   "overheatingSavings": 6489
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "1.7450116483476221",
   "coolingKwh": "134.9360404962411",
   "overheatingSavings": 6248
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0.3141767845237589",
   "coolingKwh": "194.9185272149689",
   "overheatingSavings": 7289
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "1.0681156719488256",
   "coolingKwh": "182.83279574412998",
   "overheatingSavings": 6792
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "1.6699220537072335",
   "coolingKwh": "162.76225881771668",
   "overheatingSavings": 6459
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A2",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0.12027313503563669",
   "coolingKwh": "255.15603432579442",
   "overheatingSavings": 7726
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERblue",
   "heatingKwh": "0.09772065225202689",
   "coolingKwh": "97.19073590765421",
   "overheatingSavings": 6846
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "4EVERdark",
   "heatingKwh": "0.17560727772113446",
   "coolingKwh": "90.36512190214579",
   "overheatingSavings": 6622
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 5,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "121.35935601536",
   "overheatingSavings": 7543
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERblue",
   "heatingKwh": "0.051530570849370114",
   "coolingKwh": "133.05165335695668",
   "overheatingSavings": 7261
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "4EVERdark",
   "heatingKwh": "0.17351973921953223",
   "coolingKwh": "119.01154064819333",
   "overheatingSavings": 6929
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 10,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "183.2014720590189",
   "overheatingSavings": 8143
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERblue",
   "heatingKwh": "0.034203100855122445",
   "coolingKwh": "168.88495031082556",
   "overheatingSavings": 7541
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "4EVERdark",
   "heatingKwh": "0.18307264780379556",
   "coolingKwh": "147.4727922822222",
   "overheatingSavings": 7131
 },
 {
   "location": "Riyadh",
   "variant": "E5",
   "setpoint": "T2",
   "infiltration": "A3",
   "glazing": 15,
   "coat": "clear",
   "heatingKwh": "0",
   "coolingKwh": "244.9874003552022",
   "overheatingSavings": 8395
 }
]