import * as React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import connect from "react-redux/es/connect/connect";
import {updateUserDetails, updateUserDetailsReady} from "../../actions/user-details.action";


// # Remove if tom actually decides to not use countries anymore.
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import countryData from '../calculation/CountriesData.js';

const { lookup } = countryData;
const countries = countryData.filter(value => value.emissionIntensity != null)
    .map(value => value.country)
    .sort((a, b) => a.localeCompare(b))
    .map(country => <MenuItem key={country} value={country}>{country}</MenuItem>);

    window.lookup = lookup;



const fields = [{
    property: 'firstName',
    label: 'First name',
    required: true,
    split: true
}, {
    property: 'lastName',
    label: 'Last name',
    required: true,
    split: true
},{
    property: 'city',
    label: 'City',
    required: true,
    split: true
},{
    property: 'country',
    label: 'Country',
    required: true,
    split: true,
    select: true,
    children: countries
},{
    property: 'email',
    label: 'Email',
    required: true,
    split: false,
    type: 'email'
}];

class UserDetails extends React.PureComponent {

    constructor() {
        super();
        this.state = {};
    }

    render() {
        return (
            <Grid container spacing={24}>
                {fields.map((field, index) => {
                    if (index === 0) field.autoFocus = true;
                    return <this.TextField key={field.property} {...field}>
                        {field.children}
                    </this.TextField>;
                })}
            </Grid>
        );
    }

    TextField = ({property, split = false, ...props}) => {
        const textField = <TextField
            value={this.props[property]}
            onChange={this.handleChange(property)}
            onBlur={this.checkValidity(property)}
            fullWidth
            error={!this.isValid(property)}
            {...props}
        />;

        if (split) return <Grid item xs={12} sm={6}>{textField}</Grid>;

        return <Grid item xs={12}>{textField}</Grid>;
    };

    handleChange = property => e => {
        const {value} = e.target;
        this.props.updateUserDetails({
            [property]: value
        });
        this.checkReady();
    };

    checkValidity = property => event => {
        const {value} = event.target;
        const field = fields.find(f => f.property === property);
        if (!field.required) return;
        const setValidity = valid => this.setState({[property + 'Valid']: valid}, () => this.checkReady());
        if (!value) {
            setValidity(false);
            return;
        }
        // eslint-disable-next-line default-case
        switch (property) {
            case 'email': {
                const regex = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
                if (!regex.test(value)) {
                    setValidity(false);
                    return;
                }
            }
        }
        setValidity(true);
    };

    checkReady = () => {
        this.props.updateUserDetailsReady(fields.filter(field => field.required).map(field => this.state[field.property + 'Valid']).every(value => value));
    };

    isValid = property => {
        const valid = this.state[property + 'Valid'];
        if (valid === undefined) return true;
        return valid;
    };

}

const mapStateToProps = state => ({
    firstName: state.userDetails.firstName,
    lastName: state.userDetails.lastName,
    city: state.userDetails.city,
    country: state.userDetails.country,
    email: state.userDetails.email
});

const mapDispatchToProps = dispatch => ({
    updateUserDetails: data => dispatch(updateUserDetails(data)),
    updateUserDetailsReady: ready => dispatch(updateUserDetailsReady(ready))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
