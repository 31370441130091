export const NEXT_STEP = "NEXT_STEP";
export const PREV_STEP = "PREV_STEP";

export const nextStep = () => dispatch => {
    dispatch({
        type: NEXT_STEP
    });
};

export const prevStep = () => dispatch => {
    dispatch({
        type: PREV_STEP
    });
};