import {combineReducers} from 'redux';
import themeReducer from './theme.reducer';
import buildingDetailsReducer from './building-details.reducer';
import userDetailsReducer from './user-details.reducer';
import mainPageStepReducer from './main-page-step.reducer';

export default combineReducers({
    theme: themeReducer,
    buildingDetails: buildingDetailsReducer,
    userDetails: userDetailsReducer,
    mainPageStep:  mainPageStepReducer
});