import * as React from 'react';
import styles from './Calculation.module.css';
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import data4ever from './4ever.js';
import CountriesData from './CountriesData.js';
import {calculate4everBlue, calculate4everDark, calculateAnnualSaving, calculateResults, calculates00p} from "./calculation.js";
import { calculateClosestLocation } from "./locator";
import Avatar from "@material-ui/core/Avatar/Avatar";
import { Link } from 'react-router-dom';
import ShopifyBuyForEverBlue from "../shopify/shopify-buy-4everblue";
import ShopifyBuyForEverDark from '../shopify/shopify-buy-4everdark';
import { TextField } from '@material-ui/core';

class Calculation extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            windowarea: this.props.input.windowarea,
            kwhPrice: 0,
            forEverBlueResults: { },
            forEverDarkResults: { },
            noCoatingResults: { }
        }

        const { input } = this.props;
        const { area, windowarea } = input;

        const filter = {
            location: calculateClosestLocation(input.path),
            variant: input.variant,
            glazing: input.glazing,
            infiltration: 'A3',
            setpoint: 'T2'
        };

        const data = data4ever.filter(d => {
            for (const key in filter) {
                if (d[key] === undefined || d[key] !== filter[key])
                    return false;
            }
            return true;
        }
        );

        const countryData = CountriesData.find(data => data.country === input.country)

        if (data === undefined || data.length === 0) return this.renderError();
        var { forEverBlueResults, forEverDarkResults, noCoatingResults } = calculateResults(area, countryData.kwhPrice, countryData.emissionIntensity, 0.99, 0.85, 1.5,data);

        this.state.forEverBlueResults = forEverBlueResults
        this.state.forEverDarkResults = forEverDarkResults
        this.state.noCoatingResults = noCoatingResults
        this.state.kwhPrice = countryData.kwhPrice

        this.s00p = calculates00p(windowarea);
        this.forEverBlue = calculate4everBlue(windowarea);
        this.forEverDark = calculate4everDark(windowarea);
        this.input = input
        this.countryData = countryData
    }

    render() {
        return (
            <div className={styles.container}>
                <Typography variant="h5" gutterBottom>
                    Congratulations {this.input.firstname}, you perfectly completed the 4EVER pre-calculation tool.
                </Typography>
                <Typography variant="subtitle1">
                    <br />
                    Your building in {this.input.city} with it's {this.input.area}m² roof is currently using:
                    <br />
                    {this.state.noCoatingResults.grossCoolingKwh} kWh electricity on cooling the building by airconditioning.
                    <br /><br />
                    After the application of 4EVERblue on your skylights it will only be using:
                    <br />
                    {this.state.forEverBlueResults.grossCoolingKwh} kWh electricity on cooling the building, that is a savings of {this.state.forEverBlueResults.grossCoolingKwhDiff}% or {this.state.forEverBlueResults.kwhDifference} kWh.
                    <br/>
                    You will reduce your annual CO2 emission by {this.state.forEverBlueResults.annualCo2Reduction} kg CO2.
                    <br/>
                    Because of the current clear skylights, the interior gets annually {this.state.noCoatingResults.overheatingHoursSavings} hours above 25 ℃.
                    <br/>
                    After the application of 4EVERblue  your interior will only be {this.state.forEverBlueResults.overheatingHoursSavings} hours above 25 ℃
                    <Typography variant="h6" gutterBottom component="div">
                        This results in an annual saving of {this.state.forEverBlueResults.annualSaving} €.
                    </Typography>
                    For your {this.state.windowarea}m² skylights you need {this.forEverBlue.liters} liter 4EVERblue and {this.s00p.liters} liter s00p SC
                    <br /><br />
                    After the application of 4EVERdark on your skylights it will only be using:
                    <br />
                    {this.state.forEverDarkResults.grossCoolingKwh} kWh electricity on cooling the building, that is a savings of {this.state.forEverDarkResults.grossCoolingKwhDiff}% or {this.state.forEverDarkResults.kwhDifference} kWh.
                    <br />
                    You will reduce your annual CO2 emission by {this.state.forEverDarkResults.annualCo2Reduction} kg CO2.
                    <br/>
                    Because of the current clear skylights, the interior gets annually {this.state.noCoatingResults.overheatingHoursSavings} hours above 25 ℃.
                    <br/>
                    After the application of 4EVERdark  your interior will only be {this.state.forEverDarkResults.overheatingHoursSavings} hours above 25 ℃
                    <Typography variant="h6" gutterBottom component="div">
                        This results in an annual saving of {this.state.forEverDarkResults.annualSaving} €.
                    </Typography>
                    For your {this.state.windowarea}m² skylights you need {this.forEverDark.liters} liter 4EVERdark and {this.s00p.liters} liter s00p SC
                    <br/>
                    <br/>
                    <Typography variant="h6" gutterBottom component="div">
                        kWh price used in calculation: <TextField type="text" value={this.state.kwhPrice} onChange={this.handleInputChanged.bind(this)}></TextField>
                        <Button variant="contained" color="primary" onClick={this.calculateWithNewKwh}>Calculate again</Button>
                    </Typography>
                    <br/>
                    <Typography variant="caption" display="block" gutterBottom>
                        Electricity price for {this.countryData.country}: {this.countryData.kwhPrice}€
                        <br/>
                        Greenhouse gas emission for {this.countryData.country}: {this.countryData.emissionIntensity} kilogram CO₂ 
                    </Typography>              
                </Typography>
                <div className={styles.avatarContainer}>
                    <Avatar alt="Tom Huysmans" src="/images/tom-huysmans.jpg" className={styles.avatar} />
                    <Typography variant='subtitle2'>
                        Tom Huysmans
                    </Typography>
                    <Typography variant='caption'>
                        CEO of Liquisol
                    </Typography>
                </div>
                {/* <div className={styles.shopify}>
                    <ShopifyBuyForEverBlue/>
                    <ShopifyBuyForEverDark/>
                </div> */}

                <div className={styles.startOverContainer + ' ' + styles.flex}>
                    <div className={styles.grow} />
                    <Button
                        component={Link}
                        to='/'
                        variant='contained'
                        color='primary'
                    >
                        Start over
                    </Button>
                </div>
            </div>
        );
    }

    handleInputChanged(event) {
        this.setState({
          kwhPrice: event.target.value
        }); 
    }

    calculateWithNewKwh = () =>
    {    
        var newForEverBlueSavings = calculateAnnualSaving(this.state.forEverBlueResults.kwhDifference, this.state.forEverBlueResults.grossCoolingKwhDiff, this.state.kwhPrice);
        var newForEverDarkSavings = calculateAnnualSaving(this.state.forEverDarkResults.kwhDifference, this.state.forEverDarkResults.grossCoolingKwhDiff, this.state.kwhPrice);

        this.setState(prevState => ({forEverBlueResults: { ...prevState.forEverBlueResults, annualSaving: newForEverBlueSavings}}))
        this.setState(prevState => ({forEverDarkResults: { ...prevState.forEverDarkResults, annualSaving: newForEverDarkSavings}}))
    };

    renderError = () => (
        <div className={styles.container}>
            <Typography variant="h5" gutterBottom>
                Something unfortunately went wrong.
            </Typography>
            <Typography variant="subtitle1">
                We're sorry for your inconvenience.
            </Typography>

            <div className={styles.startOverContainer + ' ' + styles.flex}>
                <div className={styles.grow} />
                <Button
                    component={Link}
                    to='/'
                    variant='contained'
                    color='primary'
                >
                    Start over
                </Button>
            </div>
        </div>
    );
}

export default Calculation;
