import React from "react";
import styles from "./SelectorMapHelper.module.css"
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import BuildingSearchHelper from "./MapHelperSteps/BuildingSearchHelper";
import BuildingZoomInHelper from "./MapHelperSteps/BuildingZoomInHelper";
import RoofMeasuringHelper from "./MapHelperSteps/RoofMeasuringHelper";
import RoofWindowMeasuringHelper from "./MapHelperSteps/RoofWindowMeasuringHelper";
import RoofMeasuringSuccess from "./MapHelperSteps/RoofMeasuringSuccess";
import { connect } from "react-redux";
import { nextStep } from "../../../actions/main-page-step.action";

const mapHelperSteps = [
    {
        title: 'Search for your building.',
        component: <BuildingSearchHelper />
    },
    {
        title: 'Zoom in on your building.',
        component: <BuildingZoomInHelper />
    },
    {
        title: 'Measure the surface of your buildings roof.',
        component: <RoofMeasuringHelper />
    },
    {
        title: 'Measure the surface of your roofs windows.',
        component: <RoofWindowMeasuringHelper />
    },
    {
        title: 'Completed!',
        component: <RoofMeasuringSuccess />
    }
];

class SelectorMapHelper extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            activeStep: 0,
            isHidden: false
        }
    };

    render() {
        const { activeStep, isHidden } = this.state;

        return <div>
            {isHidden ? <Button variant="contained" color="primary" onClick={this.handleHide}>Show Tutorial</Button> 
            : <div>
            <h1>4EVER measuring guidance <Button variant="contained" color="primary" onClick={this.handleHide}>Hide</Button></h1>
            <Stepper activeStep={activeStep} orientation="vertical">
                {mapHelperSteps.map((step) => (
                    <Step key={step.title}>
                        <StepLabel>{step.title}</StepLabel>
                        <StepContent>
                            <Typography component={'div'}>{step.component}</Typography>
                            <div >
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={this.handleBack}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleNext}
                                        disabled={activeStep === mapHelperSteps.length - 1 ? !this.props.isAreaDefined : false }
                                    >
                                        {activeStep === mapHelperSteps.length - 1 ? 'Finish' : 'Next Step'}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </div>}
            
        </div>
    };

    handleHide = () =>
    {    
        this.setState(prevState => ({isHidden: !prevState.isHidden}))
    };

    handleNext = () => {
        if(this.state.activeStep === 4){
            this.props.passedSave();
            this.props.nextStep();
        }
        this.setState({activeStep: this.state.activeStep + 1})
    };

    handleBack = () => {
        this.setState({activeStep: this.state.activeStep - 1})
    };

    handleReset = () => {
        this.setState({activeStep: 0})
    };
}

const mapDispatchToProps = dispatch => ({
    nextStep: () => dispatch(nextStep())
});

export default connect(null, mapDispatchToProps)(SelectorMapHelper);
