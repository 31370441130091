import {UPDATE_BUILDING_DETAILS, UPDATE_BUILDING} from "../actions/building-details.action";
import _ from 'lodash';

const initState = {
    variant: 'E2',
    height: 6,

    path: [],
    encodedPath: '',
    area: 0,
    totalWindowArea: 0,
    glazingPercentage: 5,
    ready: false
};

const reducer = (state = initState, action) => {
    switch (action.type) {

        case UPDATE_BUILDING_DETAILS: {
            const {height, variant} = action.payload;
            return _.merge({
                ...state
            }, {
                height, variant
            });
        }

        case UPDATE_BUILDING: {
            const {path, area, totalWindowArea, glazingPercentage, encodedPath} = action.payload;
            const ready = area > 0;
            return {
                ...state,
                ready,
                path, area, totalWindowArea, glazingPercentage, encodedPath
            }
        }

        default:
            return state;
    }
};

export default reducer;