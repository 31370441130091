import React from "react";
import styles from "./RoofSearchHelper.module.css";

class RoofWindowMeasuringHelper extends React.PureComponent {

    render() {
        return <div>
            <p>Now we can measure the surface of your windows. Begin on a corner of your roof's window and click once.</p>
            <p>Now the drawing tool has been activated and by clicking on another corner it will draw a line between both corners.</p>
            <p>Go around the window until you're back at your starting point and the window is highlighted.</p>
            <p>Repeat this until all of your windows have been measured.</p>
            <img className={styles.measuringImage} src='/images/window-measuring-animation.gif'
                 alt="Gif-roof-window-measuring"/>
        </div>
    }

}

export default RoofWindowMeasuringHelper;