import * as React from "react";

class ShopifyBuyForEverBlue extends React.PureComponent {

    componentDidMount() {
        this.loadScript();
    }

    render() {
        return <div id='collection-component-1647255229772'/>;
    }

    loadScript() {
        var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
        if (window.ShopifyBuy) {
            if (window.ShopifyBuy.UI) {
                ShopifyBuyInit();
            } else {
                loadScript();
            }
        } else {
            loadScript();
        }
        function loadScript() {
            var script = document.createElement('script');
            script.async = true;
            script.src = scriptURL;
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
            script.onload = ShopifyBuyInit;
        }
        function ShopifyBuyInit() {
            var client = window.ShopifyBuy.buildClient({
                domain: 'liquisol.myshopify.com',
                storefrontAccessToken: '34c7dea8d94725b9c19cf621a55fef97',
            });
            window.ShopifyBuy.UI.onReady(client).then(function (ui) {
                ui.createComponent('collection', {
                    id: '162024325202',
                    node: document.getElementById('collection-component-1647255229772'),
                    moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
                    options: {
                        "product": {
                            "styles": {
                                "product": {
                                    "@media (min-width: 601px)": {
                                        "max-width": "calc(25% - 20px)",
                                        "margin-left": "20px",
                                        "margin-bottom": "50px",
                                        "width": "calc(25% - 20px)"
                                    },
                                    "img": {
                                        "height": "calc(100% - 15px)",
                                        "position": "absolute",
                                        "left": "0",
                                        "right": "0",
                                        "top": "0"
                                    },
                                    "imgWrapper": {
                                        "padding-top": "calc(75% + 15px)",
                                        "position": "relative",
                                        "height": "0"
                                    }
                                },
                                "button": {
                                    "font-family": "Avant Garde, sans-serif",
                                    "font-size": "17px",
                                    "padding-top": "16.5px",
                                    "padding-bottom": "16.5px"
                                },
                                "quantityInput": {
                                    "font-size": "17px",
                                    "padding-top": "16.5px",
                                    "padding-bottom": "16.5px"
                                }
                            },
                            "buttonDestination": "checkout",
                            "text": {
                                "button": "Buy now"
                            }
                        },
                        "productSet": {
                            "styles": {
                                "products": {
                                    "@media (min-width: 601px)": {
                                        "margin-left": "-20px"
                                    }
                                }
                            }
                        },
                        "modalProduct": {
                            "contents": {
                                "img": false,
                                "imgWithCarousel": true,
                                "button": false,
                                "buttonWithQuantity": true
                            },
                            "styles": {
                                "product": {
                                    "@media (min-width: 601px)": {
                                        "max-width": "100%",
                                        "margin-left": "0px",
                                        "margin-bottom": "0px"
                                    }
                                },
                                "button": {
                                    "font-family": "Avant Garde, sans-serif",
                                    "font-size": "17px",
                                    "padding-top": "16.5px",
                                    "padding-bottom": "16.5px"
                                },
                                "quantityInput": {
                                    "font-size": "17px",
                                    "padding-top": "16.5px",
                                    "padding-bottom": "16.5px"
                                }
                            },
                            "text": {
                                "button": "Add to cart"
                            }
                        },
                        "cart": {
                            "styles": {
                                "button": {
                                    "font-family": "Avant Garde, sans-serif",
                                    "font-size": "17px",
                                    "padding-top": "16.5px",
                                    "padding-bottom": "16.5px"
                                }
                            },
                            "text": {
                                "total": "Subtotal",
                                "button": "Checkout"
                            }
                        },
                        "toggle": {
                            "styles": {
                                "toggle": {
                                    "font-family": "Avant Garde, sans-serif"
                                },
                                "count": {
                                    "font-size": "17px"
                                }
                            }
                        }
                    },
                });
            });
        }
    }
}

export default ShopifyBuyForEverBlue;
