import {NEXT_STEP,PREV_STEP} from "../actions/main-page-step.action";

const initState = {
    step: 0
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case NEXT_STEP: {
            return{
                step: state.step + 1
            }
        }

        case PREV_STEP: {
            return{
                step: state.step - 1
            }
        }

        default:
            return state;
    }
};

export default reducer;