import * as React from 'react';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

import styles from './BuildingDetails.module.css';
import {connect} from "react-redux";
import {updateBuildingDetails} from "../../actions/building-details.action";
import StaticMap from "../../components/GoogleMaps/StaticMap";
import SelectorMap from "../../components/GoogleMaps/SelectorMap";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import RoofVariantDialog from "./RoofVariantDialog";

class BuildingDetails extends React.PureComponent {

    render() {
        const { path } = this.props;
        return (<>

            <div className={styles.map}>
                <StaticMap path={path}/>
                <div/>
                <SelectorMap/>
            </div>


            <Grid container spacing={24}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label='Variant'
                        value={this.props.variant}
                        onChange={this.handleVariantChange}
                        fullWidth
                        helperText={<RoofVariantDialog/>}
                        select
                    >
                        <MenuItem value={"E1"}>Variant 1</MenuItem>
                        <MenuItem value={"E2"}>Variant 2 (default)</MenuItem>
                        <MenuItem value={"E3"}>Variant 3</MenuItem>
                        <MenuItem value={"E4"}>Variant 4</MenuItem>
                        <MenuItem value={"E5"}>Variant 5</MenuItem>
                    </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label='Height'
                        value={this.props.height}
                        onChange={this.handleHeightChange}
                        fullWidth
                        select
                    >
                        <MenuItem value={6}>6m</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
        </>);
    };

    handleVariantChange = event => {
        this.props.updateBuildingDetails({
            variant: event.target.value
        });
    };

    handleHeightChange = event => {
        this.props.updateBuildingDetails({
            height: event.target.value
        });
    };

}

const mapStateToProps = state => ({
    height: state.buildingDetails.height,
    variant: state.buildingDetails.variant,

    path: state.buildingDetails.path,
    area: state.buildingDetails.area,
    totalWindowArea: state.buildingDetails.totalWindowArea,
    ready: state.buildingDetails.ready
});

const mapDispatchToProps = dispatch => ({
    updateBuildingDetails: data => dispatch(updateBuildingDetails(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(BuildingDetails);
