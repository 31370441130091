export const UPDATE_BUILDING_DETAILS = "UPDATE_BUILDING_DETAILS";
export const UPDATE_BUILDING = "UPDATE_BUILDING";

export const updateBuildingDetails = data => dispatch => {
    dispatch({
        type: UPDATE_BUILDING_DETAILS,
        payload: data
    });
};

export const updateBuilding = (path, area, totalWindowArea, glazingPercentage, encodedPath) => dispatch => {
    dispatch({
        type: UPDATE_BUILDING,
        payload: {
            path, area, totalWindowArea, glazingPercentage, encodedPath
        }
    });
};