import * as React from 'react';
import styles from './SelectorMap.module.css';
import Loader from "../Loader";
import connect from "react-redux/es/connect/connect";
import GMap from "./GMap";
import Dialog from "../Dialog";
import {updateBuilding} from "../../actions/building-details.action";
import Drawer from "@material-ui/core/Drawer/Drawer";
import SelectorMapHelper from "./SelectorMapHelper/SelectorMapHelper";

class SelectorMap extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            roofPath: undefined,
            windowsPath: undefined,
            isAreaDefined: false
        }
    }

    render() {
        return <>
            <Dialog title="Select Building" onSave={this.handleSave} isAreaDefined={this.state.isAreaDefined}>
                <div className={styles.container}>
                    <div className={styles.helperContainer}>
                        <SelectorMapHelper isAreaDefined={this.state.isAreaDefined} passedSave={this.handleSave}/>
                    </div>
                    <GMap
                        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3&key=AIzaSyDsXZuhEc3wv7a4LXej8IUAgVCHG4fVfnA&libraries=geometry,drawing,places"
                        loadingElement={<Loader/>}
                        containerElement={<div className={styles.mapContainer}/>}
                        mapElement={<div className={styles.map}/>}
                        onBuildingSelect={this.handleBuildingSelect}
                    />
                </div>
            </Dialog>
        </>;
    }

    renderDrawer() {
        return <Drawer
            variant="permanent"
            anchor="left"
            classes={{
                paper: styles.drawerPaper,
            }}
        >
            drawer
        </Drawer>
    }

    handleBuildingSelect = buildingSurfaces => {
        const buildingSurfacesPaths = buildingSurfaces.map(building => building.getPath());

        this.setState({
            roofPath: buildingSurfacesPaths.shift(),
            windowsPath: buildingSurfacesPaths
        });

        this.checkIfAreaIsDefined();
    };

    handleSave = () => {
        const {roofPath, windowsPath} = this.state;
        const roofArea = window.google.maps.geometry.spherical.computeArea(roofPath);
        const totalWindowArea = this.calculateTotalWindowArea(windowsPath)
        const glazingPercentage = this.calculateGlazingPercentage(totalWindowArea, roofArea);

        const encodedPath = window.google.maps.geometry.encoding.encodePath(roofPath);
        this.props.updateBuilding(roofPath.getArray(), roofArea, totalWindowArea, glazingPercentage, encodedPath);
    };

    calculateTotalWindowArea = (windowsPath) => {
        let totalWindowArea = 0;
        windowsPath.forEach(roofWindow => {
            totalWindowArea += window.google.maps.geometry.spherical.computeArea(roofWindow);
        });

        return totalWindowArea;
    }

    calculateGlazingPercentage = (totalWindowArea, roofArea) => {
        const glazingPercentage = Math.round((totalWindowArea / roofArea) * 100);
        
        if (glazingPercentage > 12)
           return 15;

        if (glazingPercentage >= 7 && glazingPercentage <= 12)
           return 10;

        return 5;
    }

    checkIfAreaIsDefined = () => {
        if (this.state.roofPath) {
            this.setState({
                isAreaDefined: true
            });
        } else {
            this.setState({
                isAreaDefined: false
            });
        }
    };

}

const mapDispatchToProps = dispatch => ({
    updateBuilding: (building, area, totalWindowArea, glazingPercentage, encodedPath) => dispatch(updateBuilding(building, area, totalWindowArea, glazingPercentage,encodedPath))
});

export default connect(null, mapDispatchToProps)(SelectorMap);
