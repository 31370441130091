export default [
    {
        name: 'London',
        lat: 51.5286208,
        lon: -0.1716373
    },
    {
        name: 'London', //Amiens
        lat: 50.0596479,
        lon: 2.0718584
    },
    {
        name: 'London', //Luxemburg
        lat: 49.0187306,
        lon: 5.3309348
    },
    {
        name: 'London', //Münster
        lat: 51.9502135,
        lon: 7.4836737
    },
    {
        name: 'London', //Bremen
        lat: 53.1201548,
        lon: 8.5958625
    },
    {
        name: 'Nancy',
        lat: 48.6880806,
        lon: 6.1646822
    },
    {
        name: 'Nancy', // Caen
        lat: 49.1846918,
        lon: -0.4073477
    },
    {
        name: 'Nancy', // Paris
        lat: 48.8589465,
        lon: 2.2768243
    },
    {
        name: 'Nancy', // Metz
        lat: 49.1048759,
        lon: 6.1611287
    },
    {
        name: 'Nancy', // Frankfurt am Main
        lat: 50.0194226,
        lon: 7.0757839
    },
    {
        name: 'Nancy', // Nurenberg
        lat: 49.4362318,
        lon: 10.9926137
    },
    {
        name: 'Nancy', // München
        lat: 48.1541019,
        lon: 11.0013266
    },
    {
        name: 'Nancy', // Trento
        lat: 46.0805156,
        lon: 11.0852506
    },
    {
        name: 'Nancy', // Como
        lat: 45.8005902,
        lon: 9.0503231
    },
    {
        name: 'Nancy', // Aosta, Valle d'Aosta
        lat: 45.7227561,
        lon: 7.1116548
    },
    {
        name: 'Nancy', // Grenoble
        lat: 45.1842884,
        lon: 5.6804349
    },
    {
        name: 'Nancy', // Aurilac
        lat: 44.928111,
        lon: 2.4064839
    },
    {
        name: 'Nancy', // Bordeaux
        lat: 44.8637733,
        lon: -0.6211171
    },
    {
        name: 'Madrid',
        lat: 40.4379332,
        lon: -3.7495755
    },
    {
        name: 'Madrid', // Biaritz
        lat: 43.4709897,
        lon: -1.5733429
    },
    {
        name: 'Madrid', // Toulouse
        lat: 43.6008061,
        lon: 1.3626317
    },
    {
        name: 'Madrid', // Montperlier
        lat: 43.6100609,
        lon: 3.7390635
    },
    {
        name: 'Madrid', // Kaapstad
        lat: -33.9146494,
        lon: 18.3752023
    },
    {
        name: 'Madrid', // Nice
        lat: 43.7032979,
        lon: 7.1826012
    },
    {
        name: 'Madrid', // Torino
        lat: 45.0702387,
        lon: 7.5998784
    },
    {
        name: 'Madrid', // Milano
        lat: 45.4626561,
        lon: 8.9075052
    },
    {
        name: 'Madrid', // Padua
        lat: 45.4065037,
        lon: 11.8912455
    },
    {
        name: 'Madrid', // Zagreb
        lat: 45.8403491,
        lon: 15.8239076
    },
    {
        name: 'Madrid', // Sarajevo
        lat: 43.8938847,
        lon: 18.2425674
    },
    {
        name: 'Madrid', // Tirana
        lat: 41.3310413,
        lon: 19.7827181
    },
    {
        name: 'Madrid', // Athene
        lat: 37.9908996,
        lon: 23.7032343
    },
    {
        name: 'Madrid', // Istanbul
        lat: 41.0054989,
        lon: 28.7313206
    },
    {
        name: 'Prague',
        lat: 50.0596486,
        lon: 14.3955853
    },
    {
        name: 'Prague', // Poznań
        lat: 52.4006548,
        lon: 16.7612425
    },
    {
        name: 'Prague', // Budapest
        lat: 47.4813598,
        lon: 18.9898792
    },
    {
        name: 'Prague', // Beograd
        lat: 44.8154029,
        lon: 20.282173
    },
    {
        name: 'Prague', // Sofia
        lat: 42.6955369,
        lon: 23.2537359
    },
    {
        name: 'Riyadh',
        lat: 24.7251268,
        lon: 46.6824485
    }
]