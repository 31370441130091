import React from "react";
import styles from "./BuildingSearchHelper.module.css"

class BuildingSearchHelper extends React.PureComponent {

    render() {
        return <div className={styles.container}>
            <p>Type in your company name or address of your building like you would on google maps.</p>
            <img className={styles.searchImage} src='/images/search-animation.gif' alt="Gif of search loop."/>
        </div>
    };

}

export default BuildingSearchHelper;