import * as React from 'react';
import Stepper from "@material-ui/core/Stepper/Stepper";
import Step from "@material-ui/core/Step/Step";
import Button from "@material-ui/core/Button/Button";
import StepLabel from "@material-ui/core/StepLabel/StepLabel";
import { connect } from "react-redux";
import BuildingDetails from "./building-details/BuildingDetails";
import {nextStep,prevStep} from "../actions/main-page-step.action";


import UserDetails from "./user-details/UserDetails";
import styles from './Main-page.module.css';
import EmailSent from "./email-sent/EmailSent.jsx";

const steps = [
    {
        title: "Building Details",
        component: <BuildingDetails />,
        completed: props => props.buildingDetailsReady
        //completed: () => true
    },
    {
        title: "User Details",
        component: <UserDetails />,
        completed: props => props.userDetailsReady
        //completed: () => true
    }
];

class MainPage extends React.PureComponent {

    constructor() {
        super();
        this.state = {}
    }

    render() {
        const step = steps[this.props.mainPageStep];
        return (
            <>
                <Stepper activeStep={this.props.mainPageStep}>
                    {steps.map((step, index) => {
                        return (
                            <Step key={step.title} completed={index < this.props.mainPageStep}>
                                <StepLabel>{step.title}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

                {this.props.mainPageStep === steps.length ? <EmailSent /> :
                    <>
                        <div className={styles.stepContainer}>
                            {step.component}
                        </div>
                        <div className={styles.navigation + ' ' + styles.flex}>
                            <div className={styles.grow} />
                            <Button
                                disabled={this.props.mainPageStep === 0}
                                onClick={this.handleBack}
                            >
                                Back
                                </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!step.completed(this.props)}
                                onClick={this.handleNext}
                            >
                                {this.props.mainPageStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </div>
                    </>
                }
            </>
        )
    }

    handleNext = () => {
        this.props.nextStep()
    };

    handleBack = () => {
        this.props.prevStep()
    };
}

const mapDispatchToProps = dispatch => ({
    nextStep: () => dispatch(nextStep()),
    prevStep: () => dispatch(prevStep())
});

const mapStateToProps = state => ({
    buildingDetailsReady: state.buildingDetails.ready,
    userDetailsReady: state.userDetails.ready,
    mainPageStep: state.mainPageStep.step
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
