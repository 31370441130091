import {UPDATE_USER_DETAILS, UPDATE_USER_DETAILS_READY} from "../actions/user-details.action";
import _ from 'lodash';

const initState = {
    ready: false,
    firstName: '',
    lastName: '',
    city: '',
    country: '',
    email: ''
};

const reducer = (state = initState, action) => {
    switch (action.type) {

        case UPDATE_USER_DETAILS: {
            const {firstName, lastName, city, country, email} = action.payload;

            return _.merge({
                ...state
            }, {
                firstName, lastName, city, country, email
            });
        }

        case UPDATE_USER_DETAILS_READY: {
            const ready = action.payload;
            return {
                ...state,
                ready
            }
        }

        default:
            return state;
    }
};

export default reducer;